export const LANGUAGES = {
  javascript: "JavaScript",
  python: "Python",
  java: "Java",
  php: "PHP",
  csharp: "C#",
  cpp: "C++",
  dockerfile: "Docker",
};

export function getLanguageName(techName) {
  const language = techName.toLocaleLowerCase();
  if (LANGUAGES[language]) {
    return LANGUAGES[language];
  }
  return techName;
}

<template>
  <button type="button" class="btn" @click.stop="toggleState">
    <i :class="activated ? 'fa-bookmark' : 'fa-bookmark-o'" class="fa"></i>
  </button>
</template>

<script>
import { eventBus } from "@/eventbus";

export default {
  name: "PBookmarkButton",
  props: {
    candidate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    activated: function() {
      return this.iconState;
    },
    iconState: function() {
      if (this.candidate) {
        return this.candidate.isBookmarked;
      }
      return false;
    },
  },
  methods: {
    toggleState: function() {
      let isBookmarked = this.candidate.isBookmarked;
      this.candidate.isBookmarked = !isBookmarked;
      this.candidate.seen = true;
      eventBus.$emit("candidateStateChanged", this.candidate);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;
  z-index: 3;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}
.fa-bookmark,
.fa-bookmark-o {
  height: 30px;
  font-size: 30px;
  background: linear-gradient(180deg, #ffdd09, #ffcb7c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
</style>

<template>
  <div ref="container" class="container">
    <div
      v-if="!minimized"
      id="resize"
      ref="resize"
      class="resize text-center"
      @mousedown="registerResizeEvent"
      @touchstart="registerResizeEvent"
    >
      <i class="fa fa-minus"></i>
    </div>
    <div id="chatPanel" ref="chatPanel" :class="{ 'chat-open': !closed, minimized: minimized, 'chat-preview': !userIsChatMember }" class="chat-panel">
      <div class="chat-header" @click.prevent="changeChatSize($event)">
        <span class="icon pull-left">
          <PCandidateChatIcon :chat-state="candidate.chatState" />
        </span>
        <span
          v-show="
            !minimized &&
              ((!getChannelDetailsRequest.loaded && !getChannelDetailsRequest.error) ||
                (sendMessageRequest && !sendMessageRequest.loaded && !sendMessageRequest.error))
          "
          class="loading-indicator"
        >
          <div class="fa fa-spinner fa-pulse fa-2x"></div>
        </span>
        <span v-if="!userIsChatMember" class="preview-msg" @click.prevent="changeChatSize($event)">
          Leseansicht
        </span>
        <span v-if="!minimized" class="icon pull-right">
          <div v-b-tooltip.hover.left title="Chat minimieren">
            <i class="fa fa-window-minimize"></i>
          </div>
        </span>
        <span v-if="minimized" class="icon pull-right">
          <div v-b-tooltip.hover.left title="Chat maximieren">
            <i class="fa fa-window-maximize"></i>
          </div>
        </span>
      </div>
      <div class="chat-body">
        <div id="chat-box" class="chat-box">
          <div id="custom-scroll" class="custom-scroll">
            <div class="slim-scroll-div">
              <!-- chat messages box -->
              <div v-if="getChannelDetailsRequest.loaded && currentChannel">
                <div v-if="channelDetails.messages.length === 0">
                  <div class="chat-message chat-message-get">Hallo! Hier ist es noch ziemlich leer. </div>
                  <div class="chat-message chat-message-get"
                    >Der Chat dient zur Kommunikation zwischen Ihnen und Entwicklern, die sich für Ihr Unternehmen
                    interessieren. Beginnen Sie jetzt ein Gespräch!
                  </div>
                </div>
                <div v-if="channelDetails.messages.length !== 0">
                  <CChatMessageBox
                    :messages="channelDetails.messages"
                    :members="channelDetails.members"
                    :candidate="candidate"
                  ></CChatMessageBox>
                </div>
              </div>
              <div class="slim-scroll-bar"></div>
              <div class="slim-scroll-rail"></div>
            </div>
          </div>
          <CChatWriteMessage
            ref="chatFooter"
            v-model="currentMessageInput"
            :on-draft="saveDraftMessage"
            :on-send="sendMessage"
            :channel-details="channelDetails"
            :disabled="false"
            :resume-id="accessIdentifierForResume"
          >
          </CChatWriteMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventbus";
import { services } from "../../main";
import moment from "moment";
import { debounce } from "lodash";
import PCandidateChatIcon from "@/components/candidates/PCandidateChatIcon";
import CChatWriteMessage from "@/components/message/CChatWriteMessage";
import CChatMessageBox from "@/components/message/CChatMessageBox.vue";

export default {
  name: "CCandidateDetailChat",
  components: {
    PCandidateChatIcon,
    CChatWriteMessage,
    CChatMessageBox,
  },
  props: {
    closed: {
      type: Boolean,
      default: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      candidate: {
        publicUserId: "",
        chatState: {
          chatMinimized: true,
          totalMessagesSent: 0,
          totalMessagesOfPlatformUser: 0,
          unreadMessage: false,
        },
      },
      currentMessageInput: null,
      currentChannel: "",
      isAuthenticated: services.authenticationService.isAuthenticated(),
      getChannelDetailsRequest: { loaded: false, error: null },
      sendMessageRequest: null,
      saveDraftMessageRequest: { loaded: false, error: null },
      socket: null,
      messageSentToSave: null,
      resizeObserver: null,
      userIsChatMember: true,
    };
  },
  computed: {
    accessIdentifierForResume: function() {
      if (!this.getChannelDetailsRequest.loaded || this.getChannelDetailsRequest.error) {
        return "";
      }
      let jobRequestIds = this.getChannelDetailsRequest.apiData.sources.jobRequestIds;
      if (jobRequestIds.length > 0) {
        return jobRequestIds[0];
      }

      let sponsoredLeadIds = this.getChannelDetailsRequest.apiData.sources.sponsoredLeadIds;
      if (sponsoredLeadIds.length > 0) {
        return sponsoredLeadIds[0];
      }
      return "";
    },
    startIndexOfNewMessages: function() {
      for (const [index, message] of this.channelDetails.messages.entries()) {
        if (message.unRead && !message.you) {
          return index;
        }
      }
      return null;
    },
    channelDetails: function() {
      return this.getChannelDetailsRequest.apiData;
    },
    inputPlaceholder: function() {
      let placeholder = "";
      if (this.channelDetails && this.channelDetails.members && this.channelDetails.members.length === 1) {
        placeholder = "Nachricht an " + this.channelDetails.members[0].name;
      }
      if (this.channelDetails && this.channelDetails.members && this.channelDetails.members.length > 1) {
        placeholder = "Nachricht an Ihre Chatpartner";
      }
      return placeholder;
    },
    userLeftPlatform: function() {
      if (!this.getChannelDetailsRequest.loaded) {
        return false;
      }
      let userInChatLeftPlatform = false;
      for (let message of this.channelDetails.messages) {
        userInChatLeftPlatform = userInChatLeftPlatform || message.content === "";
      }
      return userInChatLeftPlatform;
    },
  },
  watch: {
    "userIsChatMember": function() {
      this.$refs.chatFooter.userIsChatMember = this.userIsChatMember;
    },
    "getChannelDetailsRequest.loaded": function() {
      if (!this.getChannelDetailsRequest.loaded || this.getChannelDetailsRequest.error) {
        return;
      }
      this.currentMessageInput = null;
      if (this.channelDetails.draftMessage && this.channelDetails.draftMessage.content) {
        this.currentMessageInput = this.channelDetails.draftMessage.content;
      }
      this.checkUnreadMessage(this.getChannelDetailsRequest.data);
      this.userIsChatMember = this.getChannelDetailsRequest.apiData.userIsChannelMember;
      this.$refs.chatFooter.userIsChatMember = this.userIsChatMember;
    },
    "sendMessageRequest.loaded": function() {
      if (!this.sendMessageRequest.loaded || this.sendMessageRequest.error) {
        return;
      }
      this.messageSentToSave = null;
      this.currentMessageInput = null;
      this.requestChannelDetails();
      eventBus.$emit("candidateChannelEvent", this.currentChannel);
    },
    resizeWindow: function() {
      this.resizeChat();
    },
  },
  mounted() {
    this.createNotificationConnection();
    eventBus.$on("joinedCurrentChannelEvent", (data) => {
      this.userIsChatMember = data.channelMemberId ? true : false;
    });
    eventBus.$on("userLoggedIn", () => {
      this.isAuthenticated = services.authenticationService.isAuthenticated();
      this.createNotificationConnection();
    });
    eventBus.$on("candidateChatSelectChannel", (candidate) => {
      this.candidate = candidate;
      this.userIsChatMember = candidate.chatState.isEmployeeChannelMember;
      this.$refs.chatFooter.userIsChatMember = this.userIsChatMember;
      if (!this.minimized && this.candidate.channelId) {
        this.selectChannel();
      }
    });
    window.addEventListener("resize", this.resizeChat);
  },
  beforeDestroy() {
    eventBus.$off("joinedCurrentChannelEvent");
    eventBus.$off("userLoggedIn");
    eventBus.$off("candidateChatSelectChannel");
    window.removeEventListener("resize", this.resizeChat);
  },
  methods: {
    registerResizeEvent: function(event) {
      document.addEventListener("mousemove", this.resizeChat);
      document.addEventListener("touchmove", this.resizeChat);
      document.addEventListener("mouseup", this.deregisterResizeEvent);
      document.addEventListener("touchend", this.deregisterResizeEvent);
    },
    resizeChat: function(event) {
      let clientY = event.clientY;
      if (event.type == "touchmove") {
        // Don't know, where these 30 comes from but it works. (It's an Offset for the touch event)
        clientY = event.touches[0].clientY - 30;
      }
      const chatPanel = this.$refs.chatPanel;
      chatPanel.style.height =
        ((document.documentElement.clientHeight - clientY) / document.documentElement.clientHeight) * 100 + "vh";
      //this has to be calculated when window is resized
      chatPanel.style.maxHeight = document.documentElement.clientHeight - 200 + "px";
      chatPanel.style.minHeight = 230 + "px";
    },
    deregisterResizeEvent: function(event) {
      document.removeEventListener("mousemove", this.resizeChat);
      document.removeEventListener("mouseup", this.deregisterResizeEvent);
      document.removeEventListener("touchmove", this.resizeChat);
      document.removeEventListener("touchend", this.deregisterResizeEvent);
    },
    changeChatSize: function(event) {
      event.stopImmediatePropagation();
      if (this.minimized) {
        eventBus.$emit("maximizeCandidateChatComponent");
        return;
      }
      eventBus.$emit("minimizeCandidateChatComponent");
    },
    getNiceTime(timestamp) {
      moment.locale("de");
      let dateString = moment(timestamp).format("DD.MM.YYYY - hh:mm");
      if (moment(timestamp).isSame(moment(), "day")) {
        dateString = moment(timestamp).fromNow();
      }
      return dateString;
    },
    checkUnreadMessage: function(candidate) {
      if (candidate && !this.minimized && candidate.chatState.unreadMessage && candidate.chatState.isEmployeeChannelMember) {
        eventBus.$emit("candidate_chat_state_changed", candidate);
      }
    },
    selectChannel: function() {
      this.currentChannel = this.candidate.channelId;

      if (this.closed) {
        return;
      }

      this.messageSentToSave = null;
      this.currentMessageInput = null;
      this.userIsChatMember = true;
      this.requestChannelDetails();
    },
    requestChannelDetails: function() {
      this.getChannelDetailsRequest = services.messageService.getChannelDetails(this.currentChannel);
      this.getChannelDetailsRequest.data = this.candidate;
    },
    sendMessage: function() {
      if (this.sendMessageRequest && !this.sendMessageRequest.loaded) {
        return;
      }
      this.messageSentToSave = this.currentMessageInput;
      this.sendMessageRequest = services.messageService.sendMessage(this.currentChannel, this.currentMessageInput);
    },
    saveDraftMessage: debounce(function() {
      if (this.sendMessageRequest && !this.sendMessageRequest.loaded) {
        return;
      }
      if (this.messageSentToSave === this.currentMessageInput) {
        return;
      }
      if (this.currentMessageInput === null) {
        return;
      }
      this.saveDraftMessageRequest = services.messageService.saveDraftMessage(
        this.currentChannel,
        this.currentMessageInput
      );
    }, 500),
    channelHasSavedDraft: function(channel) {
      return channel.draftMessage && channel.id !== this.currentChannel;
    },
    createNotificationConnection: function() {
      if (!this.isAuthenticated) {
        return;
      }
      const userID = services.authenticationService.getUserID();
      this.socket = services.notificationsService.getNewMessageNotificationSocket();
      let channelName = `notifications_message_${userID}_new-message`;
      this.socket.on(channelName, (data) => {
        if (this.closed || !this.currentChannel) {
          return;
        }
        let jsonData = JSON.parse(data);
        let channelId = jsonData.channelId;
        if (channelId && this.currentChannel === channelId) {
          this.requestChannelDetails();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0;
  padding: 0;
}

.chat-panel {
  background-color: #fff;
  transform-origin: center bottom;
  transform: scaleY(0);
  width: 100%;
  height: 70vh;
  min-height: 2rem;
  position: relative;
  bottom: 0;
  margin: auto 0 0 0;
  resize: vertical;
  display: flex;
  flex-direction: column;
}

.chat-panel.chat-preview {
  background-image: linear-gradient(126deg, #ffffff 25%, #f9f9f9 25%, #f9f9f9 50%, #ffffff 50%, #ffffff 75%, #f9f9f9 75%, #f9f9f9 100%);
  background-size: 17.30px 23.82px;
}

.chat-header {
  cursor: pointer;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  background: linear-gradient(90deg, #539588, #68a893, #99d3ab);
  color: #fff;

  a,
  a:hover {
    color: #fff;
  }
  .icon {
    font-size: 1.5rem;
    margin: 0.5rem 1rem;
  }
  .loading-indicator {
    position: relative;
    top: 0.75rem;
  }
  .preview-msg {
    position: relative;
    top: 1rem;
    text-transform: uppercase;
  }
}


.fa-window-minimize,
.fa-window-maximize {
  cursor: pointer;
}
.chat-panel.chat-open {
  transform: scaleY(1);
}
.chat-body {
  transition: all 0.25s ease-in-out;
  transform: scaleY(1);
  transform-origin: center bottom;
  width: 100%;
  padding: 0;
  height: auto;
  display: flex;
  -webkit-box-flex: 1;
  flex: 2 1 auto;
  min-height: 0;
}

.chat-panel.chat-open.minimized .chat-body {
  transform: scaleY(0);
}
.slim-scroll-bar {
  background: rgba(0, 0, 0, 0.6);
  width: 4px;
  position: absolute;
  top: 0px;
  opacity: 0.4;
  display: none;
  border-radius: 3px;
  z-index: 99;
  right: 4px;
}

.slim-scroll-rail {
  width: 4px;
  position: absolute;
  top: 0px;
  display: none;
  border-radius: 7px;
  background: rgb(250, 250, 250);
  opacity: 0.2;
  z-index: 90;
  right: 4px;
}
/* CHATBOX */
.chat-box {
  width: 100%;
  color: #666;
  height: 100%;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  overflow-y: auto;
}

.chat-box:before {
  content: "";
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  z-index: 100;
  /*transition-delay: .1s;*/
  opacity: 0;
  visibility: hidden;
  /*transition: all 470ms cubic-bezier(.34, 1.25, .3, 1);*/
}

.custom-scroll {
  flex-grow: 2;
  overflow-y: auto;
}

.slim-scroll-div {
  position: relative;
  width: auto;
  height: auto;
}

.chat-container {
  height: 100%;
  padding: 2em;
  width: 100%;
}

.date-stamp {
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  margin: 0.25em 0 1em 0;
  color: #767676;
}

.chat-segment-sent {
  text-align: right;
  position: relative;
  margin: 0 0 1em 5em;
}

.chat-segment-get {
  text-align: left;
  position: relative;
  margin: 0 2em 1em 0;
}

.new-message {
  color: #e74c3c;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 1em;
}

.new-message > hr {
  margin-top: 0;
  margin-bottom: 0;
  border-color: #e74c3c;
}

.sender-name {
  font-weight: 700;
  margin-bottom: 0.25em;
}

.time-stamp {
  font-size: 0.9em;
  font-weight: 300;
  color: #868e96;
}

.chat-segment-sent .chat-message {
  background: #16a085;
  color: white;
  text-align: left;
}

.chat-segment-get .chat-message {
  background: #ecf0f1;
  color: #666;
  text-align: left;
}

.chat-message {
  padding: 1em 1.5em;
  position: relative;
  display: inline-block;
  margin-bottom: 0.5em;
  word-break: break-word;
  white-space: pre-wrap;
}

.deleted-segment {
  margin-top: 0.5em;
  color: white;
  background: #95a5a6;
  text-align: center;
  position: relative;
  border-radius: 1em;
}

.deleted-info {
  padding: 0.5em;
  position: relative;
  display: inline-block;
}

.chat-message-sent {
  border-radius: 1em 1em 0 1em;
}

.chat-message-get {
  border-radius: 1em 1em 1em 0;
}

.write-message {
  background-color: white;
  width: 100%;
  bottom: 0;
  position: fixed;
  height: 180px;
  padding: 0 1em 1em 1em;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}

.border-faded {
  -webkit-box-flex: 1;
  flex: 1;
  margin-left: 1em;
  margin-right: 1em;
  position: relative;
  border: 0 solid rgba(29, 29, 29, 0.07);
  border-top-width: 1px;
}

.border-faded > div {
  padding: 1rem 0 0.5em;
  border-radius: 0;
}

.border-faded:focus {
  outline: none !important;
}

.border-faded > div:focus {
  outline: none !important;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
}

textarea::placeholder {
  color: #909090;
}

.message-input {
  height: 80px;
  padding: 0;
}

.message-send {
  height: 60px;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.message-send:focus {
  outline: none;
}

.error-message {
  font-size: 12px;
  position: absolute;
  z-index: 10000;
  bottom: 60px;
  padding: 12px;
  border-radius: 12px;
  animation: showandhide 5s;
  opacity: 0;
  visibility: hidden;
}

@keyframes showandhide {
  0% {
    opacity: 0;
    visibility: visible;
  }
  50% {
    opacity: 1;
  }
  100% {
    display: none;
    visibility: hidden;
  }
}

.send-btn {
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  outline: 0;
  color: white;
  background-color: #16a085;
  border-color: #16a085;
  border-radius: 99px;
  padding: 0.5em 1.5em;
  opacity: 0.9;
  transition: none !important;
}

.send-btn:hover {
  opacity: 1;
  background-color: #16a085;
  border-color: #16a085;
}

.send-text {
  margin-right: 1em;
}

.disabled-button {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}

@media (min-width: 576px) {
  .chat-component {
    max-width: 500px;
  }
}

.additional-actions-button {
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.65em 0.9em;
  margin-right: 0.75rem;
  position: relative;
  transition: all 0.3s 0.1s;
}

.additional-actions-button i {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.5rem;
  vertical-align: bottom;
}

.additional-actions-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
}

.additional-actions-div {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #f7f9fa;
  width: 250px;
  left: -249%;
  bottom: 55px;
  z-index: 1;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 6px 9px rgba(0, 0, 0, 0.2);
  transition: all 0.3s 0.1s;
  padding: 12px;
  border-radius: 12px;
}

.additional-actions-div:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-top-color: #f7f9fa;
  border-bottom: 0;
  margin-left: -11px;
  margin-bottom: -11px;
}

.additional-actions-div:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  margin-left: -13px;
  margin-bottom: -13px;
}

@media (max-width: 430px) {
  .additional-actions-div {
    position: fixed;
    width: 70%;
    left: 5px;
    bottom: 82px;
  }

  .additional-actions-div:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-bottom: 0;
    border-top-color: #f7f9fa;
    border-right: 0;
    margin-left: -80px;
    margin-bottom: -16px;
  }

  .additional-actions-div:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 19px solid transparent;
    border-bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-right: 0;
    margin-left: -82px;
    margin-bottom: -19px;
  }
}

@media (max-width: 350px) {
  .send-btn {
    font-size: 0.7rem;
  }

  .additional-actions-div {
    width: 65%;
  }
}

.additional-actions-button:hover .additional-actions-div {
  opacity: 1;
  visibility: visible;
}

.additional-actions-div i {
  font-size: 1rem;
  vertical-align: bottom;
  margin-right: 0.2rem;
}

.additional-actions-div ul li {
  font-weight: bold;
  line-height: 1rem;
  color: #0e1111;
  padding: 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  transition: background-color 0.1s;
  border-radius: 12px;
}

.additional-actions-div ul {
  margin-bottom: 0;
}

.additional-actions-div ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.additional-actions-div ul li a,
.additional-actions-div ul li a:hover,
.additional-actions-div ul li a:active {
  text-decoration: none;
  color: inherit;
}
.fa-comment-o {
  -webkit-text-stroke: 4px transparent;
}
.resize {
  width: 100%;
  line-height: 10px;
  vertical-align: middle;
  height: 10px;
  background: #ecf0f1;
  cursor: ns-resize;
}

/* Nice scroll bars */
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 14px;
  padding: 20px;
}

::v-deep ::-webkit-scrollbar-button {
  display: none;
}

::v-deep ::-webkit-scrollbar-track {
  background-color: inherit;
}

::v-deep ::-webkit-scrollbar-track-piece {
  background-color: inherit;
}

::v-deep ::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #b9b9b9;
  border-radius: 4px;
}

::v-deep ::-webkit-scrollbar-corner {
  background-color: #b9b9b9;
}

::v-deep ::-webkit-resizer {
  background-color: inherit;
}

::v-deep div {
  scrollbar-color: #b9b9b9 auto;
}
</style>

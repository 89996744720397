<template>
  <div>
    <button type="button" class="btn" @click="openChat()">
      <PCandidateChatIcon :chat-state="candidate.chatState" />
    </button>
  </div>
</template>

<script>
import { eventBus } from "@/eventbus";
import PCandidateChatIcon from "@/components/candidates/PCandidateChatIcon";

export default {
  name: "CChatButton",
  components: {
    PCandidateChatIcon,
  },
  props: {
    interactJobOfferId: {
      type: String,
      default: "",
    },
    candidate: {
      type: Object,
      default: () => ({
        chatState: {
          chatMinimized: true,
          totalMessagesSent: 0,
          totalMessagesOfPlatformUser: 0,
          unreadMessage: false,
        },
      }),
    },
  },
  data() {
    return {};
  },
  mounted() {
    let eventName = this.candidate.publicUserId + "_chat_state_changed";
    eventBus.$on(eventName, (chatState) => {
      this.candidate.chatState = chatState;
    });
  },
  beforeDestroy() {
    let eventName = this.candidate.publicUserId + "_chat_state_changed";
    eventBus.$off(eventName);
  },
  methods: {
    openChat: function() {
      this.candidate.chatState.chatMinimized = false;
      eventBus.$emit("openCandidateChatComponent", {
        candidate: this.candidate,
        interactJobOfferId: this.interactJobOfferId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;
  color: #539588;
  z-index: 3;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}
</style>

<template>
  <div ref="chatContainer" class="chat-container">
    <div
      v-for="(message, index) in messageList"
      :key="message.id"
      :ref="'chatSegment' + index"
      class="chat-segment"
      :class="message.you ? 'chat-segment-sent' : 'chat-segment-get'"
    >
      <div v-if="startIndexOfNewMessages === index" v-once class="new-message">Neu
        <hr/>
      </div>
      <div v-if="message.content !== ''">
        <div v-if="Array.isArray(message.content)">
          <div
            v-for="(messagePart, messageIndex) of message.content"
            :key="messageIndex"
            :class="{'emoji-message': isEmoji(message.content), 'chat-message-sent': message.you, 'chat-message-get': !message.you,'image-message': isImageURL(message.content)}"
            class="chat-message"
          >
            <div
              v-if="typeof messagePart == 'string'"
              class="chat-message-string"
              v-html="processMessage(messagePart)"
            ></div>
            <div
              v-if="
                typeof messagePart == 'object' && messagePart !== null && messagePart.component && messagePart.static
              "
            >
              <component
                :is="messagePart.component"
                v-bind="messagePart.static"
                :you="message.you"
                :candidate="candidate"
              ></component>
            </div>
          </div>
        </div>
        <div
          v-if="typeof message.content == 'string'"
          :class="{'emoji-message': isEmoji(message.content), 'chat-message-sent': message.you, 'chat-message-get': !message.you,'image-message': isImageURL(message.content)}"
          class="chat-message"
        >
          <div
            :key="`message${index}user${message.senderName}`"
            class="chat-message-string"
            v-html="processMessage(message.content)"
          ></div>
        </div>
      </div>
      <div
        v-if="message.content === ''"
        :class="message.you ? 'chat-message-sent' : 'chat-message-get'"
        class="chat-message"
      >Nachricht gelöscht.
      </div>
      <div class="time-stamp">
        <em>{{ message.you ? "" : message.senderName + " &mdash;" }}&nbsp;</em>
        {{ getNiceTime(message.serverTimeReceived) }}
      </div>
      <div v-if="userLeftPlatform && index === messages.length - 1" class="deleted-segment">
        <div class="deleted-info">{{ members[0].name }} hat die Plattform verlassen.</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import sanitizeHtml from "sanitize-html";
import WorkSamplesMessage from "./chatPluginComponents/shareWorkSamples/WorkSamplesMessage.vue";

export default {
  name: "ChatMessageBox",
  components: {WorkSamplesMessage},
  props: {
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    members: {
      type: Array,
      default: () => {
        return [];
      },
    },
    candidate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Describes if a user is deleted => messages get also deleted => display in chat
    userLeftPlatform: function () {
      let userInChatLeftPlatform = false;
      for (let message of this.messages) {
        userInChatLeftPlatform = userInChatLeftPlatform || message.content === "";
      }

      return userInChatLeftPlatform;
    },
    startIndexOfNewMessages: function () {
      for (const [index, message] of this.messages.entries()) {
        if (message.unRead && !message.you) {
          return index;
        }
      }
      return null;
    },
    messageList: function () {
      let result = [];
      this.messages.forEach((message) => {
        try {
          let json = JSON.parse(message.content);
          if (json && json instanceof Array) {
            message.content = json;
            result.push(message);
          }
        } catch (e) {
          result.push(message);
        }
      });
      return result;
    },
  },
  mounted() {
    this.jumpToLastMessage();
  },
  updated() {
    this.jumpToLastMessage();
  },
  methods: {
    getNiceTime(timestamp) {
      moment.locale("de");
      let dateString = moment(timestamp).format("DD.MM.YYYY - HH:mm");
      if (moment(timestamp).isSame(moment(), "day")) {
        dateString = moment(timestamp).fromNow();
      }

      return dateString;
    },
    jumpToLastMessage() {
      if (this.messages.length === 0) {
        return;
      }

      let indexOfLastMessage = this.messages.length - 1;
      this.$nextTick(() => {
        let lastMessageReference = this.$refs["chatSegment" + indexOfLastMessage][0];
        if (lastMessageReference) {
          lastMessageReference.scrollIntoView();
        }
      });
    },
    processMessage(message) {
      if (this.isImageURL(message)) {
        return `<img src="https://res.cloudinary.com/dspi5zyaa/image/fetch/w_500/${message}" class="chat-img-tag" alt="External Link" style="max-width: 300px; max-height: 500px"/>` + '<br/><small class="img-link">' + this.findURLs(message) + '</small>';
      }
      message = this.findURLs(message);
      message = this.findMails(message);
      message = this.sanitize(message);
      return message;
    },
    isEmoji(message) {
      try {
        let result = /^(\p{Extended_Pictographic})+$/u.test(message);
        return result;
      } catch (e) {
        return false;
      }
    },
    isImageURL(message) {
      try {
        let result = /^https?:\/\/.+\.(jpg|jpeg|png|gif)$/i.test(message);
        return result;
      } catch (e) {
        return false;
      }
    },
    sanitize(message) {
      sanitizeHtml.defaults.allowedAttributes.span = ["class"];
      return sanitizeHtml(message, {allowedAttributes: sanitizeHtml.defaults.allowedAttributes});
    },
    findMails(message) {
      let mailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}/gm;
      let match = mailRegex.exec(message);
      if (match) {
        match.forEach((x) => {
          message = message.replace(x, `<a href="mailto:${x}">${x}</a>`);
        });
      }
      return message;
    },
    findURLs(message) {
      let urlRegex = /((http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;amp;:/~+#-]*[\w@?^=%&amp;amp;/~+#-])?)/g;
      let m;
      let urls = [];
      while ((m = urlRegex.exec(message)) !== null) {
        if (m.index === urlRegex.lastIndex) {
          urlRegex.lastIndex++;
        }

        urls.push(m[0]);
      }
      urls.forEach((url) => {
        message = message.replace(url, `<a target="_blank" href="${url}">${url}</a>`);
      });
      return message;
    },
  },
};
</script>

<style scoped>
em {
  font-style: italic;
}

.chat-container {
  height: 100%;
  padding: 2em;
  width: 100%;
}

.date-stamp {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin: 0.25rem 0 1rem 0;
  color: #767676;
}

.chat-segment-sent {
  text-align: right;
  position: relative;
  margin: 0 0 1rem 5rem;
}

.chat-segment-get {
  text-align: left;
  position: relative;
  margin: 0 2rem 1rem 0;
}

::v-deep .chat-message-get a {
  color: #41a499 !important;
}

::v-deep .chat-message-sent a {
  color: #ffffff !important;
  text-decoration: underline dotted;
}

::v-deep .chat-message-sent a:hover {
  color: #eeeeee !important;
  text-decoration: #eeeeee underline solid;
}

.new-message {
  color: #e74c3c;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 1rem;
}

.new-message > hr {
  margin-top: 0;
  margin-bottom: 0;
  border-color: #e74c3c;
}

.time-stamp {
  font-size: 0.9em;
  font-weight: 300;
  color: #868e96;
}

.chat-segment-sent .chat-message {
  background: #16a085;
  color: white;
  text-align: left;
}

.chat-segment-get .chat-message {
  background: #ecf0f1;
  color: #666;
  text-align: left;
}

.chat-message {
  padding: 1em 1.5em;
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
  word-break: break-word;
  width: fit-content;
  max-width: 100%;
}

.chat-message-string {
  white-space: pre-wrap;
}

.deleted-segment {
  margin-top: 0.5em;
  color: white;
  background: #95a5a6;
  text-align: center;
  position: relative;
  border-radius: 1em;
}

.deleted-info {
  padding: 0.5em;
  position: relative;
  display: inline-block;
}

.chat-message-sent {
  border-radius: 1rem 1rem 0 1rem;
}

.chat-message-get {
  border-radius: 1rem 1rem 1rem 0;
}

.chat-message.emoji-message {
  background: transparent;
  font-size: 4rem;
  padding: 0;
}

.chat-message.emoji-message {
  background: transparent;
  font-size: 4rem;
  padding: 0;
}

.chat-message.image-message {
  background: transparent;
  padding: 0;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 0;
}

::v-deep .chat-img-tag {
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  max-width: 300px;
  max-height: 500px;
  background: #0bc0a1;
  width: 100%;
  display: inline-block;
  min-height: 3rem;
  text-align: center;
  line-height: 3rem;
}

::v-deep small.img-link > a {
  color: #319999 !important;
  font-size: 80% !important;
}

::v-deep small.img-link > a:hover {
  color: #206363 !important;
  font-size: 80% !important;
}
</style>

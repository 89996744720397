<template>
  <div class="error">
    {{ msg }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.error {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 1rem;
}
</style>

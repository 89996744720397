<template>
  <div v-if="candidate.noteEditing">
    <div class="modal-backdrop fade in"></div>
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-title">
              <strong>Notizen</strong> zu {{ candidate.anonymizedUserName }}
              <i
                v-if="!requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error"
                class="fa fa-circle-o-notch fa-spin loading-spinner"
              ></i>
            </div>
            <div class="comment-editor" style="padding-top: 15px">
              <textarea
                ref="noteInput"
                v-model="candidate.note.content"
                class="form-control"
                :disabled="
                  !requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error
                "
                :class="{ edited: candidate.noteEditing }"
                placeholder="Notizen eingeben..."
              >
              </textarea>
              <div class="icons">
                <button
                  :disabled="
                    !requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error
                  "
                  class="btn"
                  @click="resetNote()"
                  ><i class="fa fa-times"></i
                ></button>
                <button
                  :disabled="
                    !requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error
                  "
                  class="btn"
                  @click="submitNote()"
                  ><i class="fa fa-check"></i
                ></button>
              </div>
            </div>
            <div class="error-display">
              <base-error
                v-if="requestObject.setCandidateNoteRequest.loaded && requestObject.setCandidateNoteRequest.error"
                class="text-center w-100"
                >Es ist etwas <strong>schief</strong> gelaufen. <br />
                Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
                <a href="mailto:support@entwicklerheld.de">Support</a></base-error
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseError from "@/components/base/BaseError";
import { eventBus } from "@/eventbus";
import { truncate } from "lodash";

export default {
  name: "PCandidateNoteEditorModal",
  components: {
    BaseError,
  },
  data() {
    return {
      candidate: {
        type: Object,
        default: () => ({
          note: { content: "" },
          tempNote: null,
          noteEditing: false,
        }),
      },
      requestObject: {
        setCandidateNoteRequest: { loaded: true, error: null },
      },
    };
  },
  watch: {
    "requestObject.setCandidateNoteRequest.loaded": function() {
      if (this.requestObject.setCandidateNoteRequest.loaded) {
        if (!this.requestObject.setCandidateNoteRequest.error) {
          this.closeNoteEditor();
        }
      }
    },
  },
  methods: {
    closeNoteEditor: function() {
      this.candidate.tempNote = null;
      this.candidate.noteEditing = false;
      this.candidate.previewNote = truncate(this.candidate.note.content);
    },
    show: function(candidate) {
      this.candidate = candidate;
      this.requestObject.setCandidateNoteRequest = { loaded: true, error: null };
      if (!this.candidate.tempNote) {
        // save temporary note for reset
        this.candidate.tempNote = this.candidate.note.content;
      }

      this.candidate.noteEditing = true;
      this.$nextTick(() => {
        this.$refs["noteInput"].focus();
      });
    },
    resetNote: function() {
      this.candidate.note.content = this.candidate.tempNote;
      this.candidate.previewNote = truncate(this.candidate.note.content);
      this.candidate.noteEditing = false;
    },
    submitNote: function() {
      if (this.candidate.tempNote !== this.candidate.note.content) {
        eventBus.$emit("candidateNoteChanged", { candidate: this.candidate, response: this.requestObject });
      } else {
        this.closeNoteEditor();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-display {
  margin: 1rem 0rem;
}
.modal {
  display: block !important;
}
.modal-dialog {
  position: relative;
  transform: translate(0, 0);
  margin: 20% auto;
  box-shadow: $elevation6;
  border-radius: 12px;
  background-color: #fff;
}
.modal-content {
  border: none;
}
.modal-title {
  width: 100%;
  text-align: left;
  font-size: 17px;
}
.btn {
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;
}
.btn:hover {
  box-shadow: $elevation1-hover;
}
.btn:disabled,
.btn:disabled:hover {
  background-color: $hover-grey !important;
  cursor: not-allowed;
  box-shadow: $elevation1;
}
.comment-editor {
  display: grid;
  height: 10rem;
  grid-template-columns: auto 40px;
}
.comment-editor textarea {
  background: none;
  border-color: transparent;
  padding: 0px 5px;
  resize: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in-out;

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
  }
  &::placeholder {
    color: #dcdbdc;
  }
  &:focus,
  &.edited {
    background-color: #fff;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: $eh-green;
    overflow: auto;
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #a9cac4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #539588;
  }
}
.comment-editor .icons {
  display: grid;
  grid-template-rows: auto auto auto;
  vertical-align: middle;
}
.comment-editor .icons button {
  width: 26px;
  height: 26px;
  color: #fff;
  font-size: 16px;
  padding: 0;
}
.comment-editor .icons button:hover {
  opacity: 0.8;
}
.comment-editor .icons button.btn-cancel {
  background-color: #e74c3c;
}
.comment-editor .icons button.btn-okay {
  background-color: #539588;
}
.comment-editor .icons button.btn-cancel::after {
  content: "\274c";
}
.comment-editor .icons button.btn-okay::after {
  content: "\2714";
}
</style>

import { render, staticRenderFns } from "./CChatMessageBox.vue?vue&type=template&id=151e9511&scoped=true&"
import script from "./CChatMessageBox.vue?vue&type=script&lang=js&"
export * from "./CChatMessageBox.vue?vue&type=script&lang=js&"
import style0 from "./CChatMessageBox.vue?vue&type=style&index=0&id=151e9511&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151e9511",
  null
  
)

export default component.exports
<template>
  <div class="menu-wrapper">
    <div ref="menu" class="menu" :style="{ width: menuWidth, height: menuHeight }">
      <ContextMenu :start-page="1" class="menu-page" @updated="updated">
        <template #default="{}">
          <ContextMenuPage :page-number="1">
            <router-link
              class="menu-item clickable"
              target="_blank"
              :to="{
                name: 'resume-preview',
                params: { accessId: resumeId },
              }"
              ><i class="dev-file-text" stroke-width="1.5"></i>
              <p>Lebenslauf anzeigen</p>
            </router-link>
          </ContextMenuPage>
        </template>
      </ContextMenu>
    </div>
    <div class="arrow-down"></div>
  </div>
</template>

<script>
import { ContextMenuPage, ContextMenu } from "./chatContextMenu.js";


export default {
  name: "ChatFooterContextMenu",
  components: { ContextMenuPage, ContextMenu },
  props: {
    onFinish: { type: Function, default: () => {} },
    channelDetails: {
      type: Object,
      default: () => {
        return {};
      },
    },
    maxWidth: { type: Number, default: 300 },
    resumeId: { type: String, default: "" },
  },
  data() {
    return {
      menuWidth: "300px",
      menuHeight: "100px",
    };
  },
  mounted() {
    this.updated();
  },
  methods: {
    calcMenu: function() {
      this.$refs.menu.style.maxWidth = this.maxWidth + "px";
      this.$refs.menu.style.width = document.querySelector(".menu-page").clientWidth + 33 + "px";
      this.$refs.menu.style.height = document.querySelector(".menu-page").clientHeight + 25 + "px";
    },
    contextFinished(attachment, message) {
      this.onFinish(message, attachment);
    },
    updated() {
      this.$nextTick(() => this.calcMenu());
    },
  },
};
</script>
<style scoped>
.menu-wrapper {
  position: absolute;
  bottom: 64px;
  margin: 0px 12px;
  animation: createBox 0.25s;
  z-index: 100;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  position: relative;

  background: #ecf0f1;
  border-radius: 10px;
  transition: 0.25s;
  overflow: hidden;
  z-index: 500;
}

.menu:hover {
  width: 300px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid #e5e9eb;
  margin-left: 18px;

  transition: 0.25s;
}

.arrow-right {
  transform: translateX(340px);
}

@keyframes createBox {
  from {
    transform-origin: 12px 100%;
    transform: scale(0);
    z-index: 500;
  }
  to {
    transform-origin: 12px 100%;
    transform: scale(1);
    z-index: 500;
  }
}

.menu-item {
  display: flex;
  align-items: center;
  margin: 12px 0px;
  font-size: 14px;
}

.menu-item.clickable {
  cursor: pointer;
}

.menu-item p {
  margin: 0 0 0 12px;
  font-weight: 600;
}

a {
  color: #666;
}

a:hover {
  text-decoration: none;
}
</style>

<template>
  <button v-if="isVisible" v-b-tooltip.hover.top.noninteractive.left="'nach oben'" class="totop" @click="scrollToTop()"
    ><i class="fa fa-arrow-circle-up"></i
  ></button>
</template>

<script>
export default {
  name: "ScrollToTopButton",
  data() {
    return {
      isVisible: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function() {
      this.isVisible = window.scrollY > 150;
    },
    scrollToTop: function() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.totop {
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  color: #b5b5b5;
  z-index: 1000;
  font-size: 4rem;
  transition: all 0.25s ease-in-out;
  border: none;
}
.totop:hover {
  color: #dcdcdc;
}
.totop:focus {
  outline: none;
}
</style>

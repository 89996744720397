<template>
  <button v-b-tooltip.hover.top.noninteractive.d500="tooltip" type="button" class="btn" @click="onClick()">
    <i :class="candidate.isArchived ? 'fa fa-inbox' : 'fa fa-archive'"></i>
  </button>
</template>

<script>
import { eventBus } from "@/eventbus";

export default {
  name: "PArchiveButton",
  props: {
    eventName: {
      type: String,
      default: "candidateStateChanged",
    },
    candidate: {
      type: Object,
      default: () => ({
        isArchived: false,
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    tooltip: function() {
      return (this.candidate.isArchived ? "Enta" : "A") + "rchivieren";
    },
  },
  methods: {
    onClick: function() {
      if (this.candidate.publicUserId) {
        let isArchived = this.candidate.isArchived;
        this.candidate.isArchived = !isArchived;
        this.candidate.seen = true;
      }
      eventBus.$emit(this.eventName, this.candidate);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}

.fa-inbox,
.fa-archive {
  height: 30px;
  font-size: 25px;
  background: linear-gradient(180deg, #9ed7ae, #549688);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
</style>

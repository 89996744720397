<template>
  <a @click="goToReport">
    <div class="wrapper" :class="{ you: you }">
      <img class="challenge-logo" style="min-width: 40px; width: 20%" :src="challenge.image" />
      <div class="work-sample-wrapper" style="">
        <div style="color: #464646; font-size: 14px; font-weight: 600">
          <b class="worksample-tag">Arbeitsprobe</b>
        </div>
        <div style="color: #464646; font-size: 14px; margin: 8px 0">
          {{ challenge.title }}
        </div>
        <LanguageBubble
          ref="langaugeBubble"
          :has-action="false"
          :technology-list="[technology]"
          style="height: 28px; transform: scale(0.8); transform-origin: 0"
          :style="{ width: languageBubbleWidth || 'fit-content' }"
        ></LanguageBubble>
      </div>
    </div>
  </a>
</template>

<script>
import LanguageBubble from "./LanguageBubble.vue";
export default {
  name: "WorkSamplesMessage",
  components: { LanguageBubble },
  props: {
    challenge: {
      type: Object,
      default: () => {
        return {};
      },
    },
    technology: {
      type: Object,
      default: () => {
        return {};
      },
    },
    you: { type: Boolean, default: false },
    candidate: { type: Object, required: true },
  },
  data: () => {
    return {
      languageBubbleWidth: "",
    };
  },
  computed: {
    reportId: function() {
      let challengeReportId = this.candidate.challenges.filter(
        (challenge) => challenge.challengeId === this.challenge.id
      );
      if (challengeReportId.length > 0) {
        return challengeReportId[0].acceptedChallengeUuid;
      }
      return "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.languageBubbleWidth = this.$refs.langaugeBubble.$el.getBoundingClientRect().width + "px";
    });
  },
  methods: {
    goToReport: function() {
      if (this.reportId === "") {
        return;
      }
      let routeData = this.$router.resolve({
        name: "challenge-report-by-acceptance",
        params: {
          acceptedChallengeUuid: this.reportId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
  cursor: pointer;
}

a div {
  transition: 0.2s;
}
a:hover div {
  transform: scale(1.02);
}

a:hover div img,
a:hover div div {
  transform: scale(1);
}

.wrapper {
  border-radius: 10px;
  background: #00000023;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  justify-content: center;
}

.wrapper.you {
  background: #ffffff54;
}

.work-sample-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
}

.challenge-logo {
  margin-right: 40px;
}

@media (max-width: 400px) {
  .worksample-tag {
    display: none;
  }

  .wrapper {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .challenge-logo {
    margin-right: 10px;
  }
}
</style>

<template>
  <div>
    <div v-if="userIsChatMember && !isJoiningCurrentChannel">
      <div ref="menu">
        <ChatFooterContextMenu
          v-if="menu"
          ref="contextMenu"
          v-closable="{
            exclude: ['messageBar', 'menu'],
            handler: 'closeMenu',
          }"
          class="menu-wrapper"
          :on-finish="contextFinished"
          :channel-details="channelDetails"
          :max-width="parseInt($refs.messageBar.clientWidth)"
          :resume-id="resumeId"
        ></ChatFooterContextMenu>
      </div>
      <div
        v-if="contextResults.size > 0"
        class="context-result"
        :style="{ width: $refs.messageBar.clientWidth + 'px' }"
      >
        <div v-for="(context, index) of contextResults" :key="index" class="menu-item-wrapper">
          <component
            :is="context.component.component"
            v-bind="context.component.static"
            :send="sendMessageEvent"
            :on-send-finished="send"
          ></component>
          <div style="cursor: pointer" @click="removeContextPart(context.message)">
            <i class="dev-x" stroke-width="1.5" style="font-size: 20px;" color="#A01616"></i>
          </div>
        </div>
      </div>
      <div ref="messageBar" class="write">
        <div class="context-options" :class="{ 'close-menu': menu, disabled: disabled }" @click="showMenu()">
          <i class="dev-plus" style="font-size: 20px;"></i>
        </div>
        <div class="message" :data-replicated-value="value">
          <textarea
            ref="message"
            rows="1"
            :placeholder="disabled ? 'Sie können noch keine Nachricht schreiben' : 'Nachricht'"
            onInput="this.parentNode.dataset.replicatedValue = this.value;"
            :value="value"
            :disabled="disabled"
            @focus="hideMenu()"
            @input="$emit('input', $event.target.value)"
            @keyup="onTyping"
          ></textarea>
        </div>
        <div
          class="send"
          :class="{
            fly: fly,
            disabled: (value === null || (value !== null && value.length == 0)) && contextResults.size == 0,
          }"
          @mousedown="startSend()"
        >
          <i class="dev-send-plane" style="font-size: 20px;"></i>
        </div>
      </div>
    </div>
    <div v-if="!userIsChatMember" class="footer-bg">
      <button
        v-if="!showChannelJoinConfirmation"
        class="btn btn-secondary d-flex justify-content-center"
        :disabled="isJoiningCurrentChannel"
        @click.prevent="joinChannel($event)"
      >
        <i v-if="isJoiningCurrentChannel" class="fa fa-spinner fa-pulse fa-1x"></i>
        Chat beitreten
      </button>
      <div v-if="showChannelJoinConfirmation">
        <p>
          Wollen Sie dem Chat wirklich beitreten?
        </p>
        <div class="d-flex">
          <button
            class="btn btn-primary justify-content-center submit"
            @click.prevent="confirmJoinChannel($event, true)"
          >
            Ja
          </button>
          <button class="btn btn-secondary justify-content-center" @click.prevent="confirmJoinChannel($event, false)">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatFooterContextMenu from "./CChatContextMenu.vue";
import {mapState} from "vuex";
import {eventBus} from "@/eventbus";

export default {
  name: "ChatFooterComponent",
  components: {ChatFooterContextMenu},
  props: {
    value: {type: String, default: "", required: false},
    onDraft: {
      type: Function, default: () => {
      }
    },
    onSend: {
      type: Function, default: () => {
      }
    },
    channelDetails: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {type: Boolean, default: false},
    resumeId: {type: String, default: ""},
  },
  data() {
    return {
      userIsChatMember: true,
      showChannelJoinConfirmation: false,
      isJoiningCurrentChannel: false,
      joinCurrentChannelError: null,
      fly: false,
      menu: false,
      contextResults: new Set(),
      sendMessageEvent: false,
      messages: [],
    };
  },
  computed: {
    ...mapState(["isAuthenticated"]),
  },
  mounted() {
    eventBus.$on("joinedCurrentChannelEvent", (data) => {
      this.userIsChatMember = data.channelMemberId ? true : false;
      this.joinCurrentChannelError = data.error;
      this.isJoiningCurrentChannel = false;
    });
  },
  updated() {
    if (this.$refs.message) {
      this.$refs.message.rows = Math.round(
        parseInt(getComputedStyle(this.$refs.message.parentNode, ":after").height) / 20
      );
      this.alignContextMenu();

    }
  },
  beforeDestroy() {
    eventBus.$off("joinedCurrentChannelEvent");
  },
  methods: {
    confirmJoinChannel: function (event, answer) {
      event.stopImmediatePropagation();
      this.showChannelJoinConfirmation = false;
      if (!answer) {
        return;
      }
      this.isJoiningCurrentChannel = true;
      setTimeout(() => {
        eventBus.$emit("joinCurrentChannelEvent", null);
      }, 750);
    },
    joinChannel: function (event) {
      event.stopImmediatePropagation();
      this.showChannelJoinConfirmation = true;
    },
    startSend: function () {
      this.sendMessageEvent = false;
      this.messages = [];

      if ((this.contextResults.size == 0 && (this.value == "" || this.value === null)) || this.disabled) {
        return;
      }
      this.fly = true;
      setTimeout(() => (this.fly = false), 2000);
      if (this.contextResults.size == 0) {
        this.onSend(this.escapeHTML(this.value));
        this.$refs.message.rows = "1";
        this.sendMessageEvent = false;
        this.messages = [];
        return;
      }

      this.contextResults.forEach((result) => {
        this.messages.push(result.message);
      });
      this.value != null ? this.messages.push(this.escapeHTML(this.value)) : null;
      this.sendMessageEvent = true;
    },
    send: function () {
      this.contextResults = new Set();
      this.onSend(JSON.stringify(this.messages));
      this.sendMessageEvent = false;
      this.messages = [];
      this.$refs.message.rows = "1";
    },
    showMenu: function () {
      if (this.disabled) {
        return;
      }
      this.menu = this.menu ? this.closeMenu() : true;
      this.$nextTick(() => {
        this.alignContextMenu();
      });
      document.addEventListener("keydown", this.closeMenu);
    },
    closeMenu: function (keyEvent) {
      if (!keyEvent || keyEvent.key === "Escape") {
        this.hideMenu();
        document.removeEventListener("keydown", this.closeMenu);
      }
    },
    hideMenu: function () {
      this.menu = false;
    },
    onTyping: function () {
      this.onDraft(this.value);
    },
    contextFinished: function (message, component) {
      this.closeMenu();
      this.contextResults.add({message: message, component: component});
    },
    removeContextPart: function (message) {
      this.contextResults.forEach((e) => {
        if (JSON.stringify(e.message) == JSON.stringify(message)) {
          this.contextResults.delete(e);
        }
      });
      // activate rerendering of component
      this.contextResults = new Set(this.contextResults);
    },
    alignContextMenu: function () {
      if (this.$refs.contextMenu) {
        let bottom = 36 + parseInt(this.$refs.message.clientHeight);
        if (bottom > 64) {
          this.$refs.contextMenu.$el.style.bottom = (36 + parseInt(this.$refs.message.clientHeight)).toString() + "px";
        } else {
          this.$refs.contextMenu.$el.style.bottom = "64px";
        }
      }
    },
    escapeHTML: (str) => {
      return str.replace(
        /[&<>'"]/g,
        (tag) =>
          ({
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            "'": "&#39;",
            '"': "&quot;",
          }[tag] || tag)
      );
    },
  },
};
</script>
<style scoped>
.write {
  display: flex;
  padding: 8px 12px;
  margin: 12px;
  margin-top: 8px;
  background: #f7f9fa;
  border-radius: 10px;
  overflow-x: visible;
  align-items: flex-end;
}

.context-options {
  padding: 8px;
  width: 36px;
  height: 36px;
  transition: 0.25s;
  cursor: pointer;
}

.context-options:not(.disabled):hover {
  transform: rotate(90deg);
  transition: 0.25s;
}

.context-options.close-menu {
  transform: rotate(45deg);
  color: #666 !important;
}

.message {
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 12px;
  display: grid;
  align-content: end;
  line-height: 20px;
  color: #464646;
  padding-bottom: 8px;
  word-break: break-word;
}

.message::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
  overflow: hidden;
  height: fit-content;

  max-height: 100px;
}

.message textarea {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  line-height: 20px;
  resize: none;
  overflow-y: auto;
  padding: 0px;
  word-break: break-word;
}

.message textarea,
.message::after {
  grid-area: 1 / 1 / 2 / 2;
  overflow-x: hidden; /* Firefox fix */
}

.message textarea:focus-visible {
  outline: none;
}

.message textarea::placeholder {
  color: #bdbdbd;
}

.send {
  padding: 8px;
  width: 36px;
  height: 36px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px;
  transition: 0.25s;
  align-self: flex-end;

  cursor: pointer;
}

.disabled {
  color: #b4b4b4;
  cursor: inherit;
}

.send:not(.disabled):hover {
  transform: rotate(-20deg);
  transition: 0.25s;
}

.send:not(.disabled):hover.fly,
.fly {
  transform: translate(70px, 0);
  transition: 2s;
}

.menu-item-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.context-result {
  margin-bottom: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;

  width: 398px;
  background: #f7f9fa;
  border-radius: 10px;
  transition: 0.25s;
  overflow: hidden;
}

.footer-bg {
  background-color: #f7f9fa;
  padding: 1rem;
  align-items: center;
}

.footer-bg button .fa-spinner {
  margin: 0.25rem 0.5rem;
}

.footer-bg .submit {
  margin-right: 1rem;
  background-color: #16a085;
  border-color: #16a085;
  opacity: 0.8;
  transition: 0.25s;
}

.footer-bg .submit:hover {
  opacity: 1;
}
</style>

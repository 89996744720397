export const ContextMenu = {
  name: "ContextMenu",
  props: {
    startPage: { type: Number, default: 1 },
  },
  data: () => ({
    pageNumber: 1,
    currentPage: null,
    history: [],
  }),
  mounted() {
    this.pageNumber = this.startPage;
    this.getPage();
    this.displayPage();
    this.$emit("next", this.pageNumber);
  },
  methods: {
    nextPage(number) {
      this.hidePage();
      this.pageNumber = number && typeof number == "number" ? number : this.pageNumber + 1;
      this.getPage();
      if (this.currentPage === undefined) {
        this.$emit("finished");
        return;
      }
      this.$emit("next", this.pageNumber);
      this.displayPage();
    },
    prevPage() {
      this.pageNumber = this.history.pop();
      this.hidePage(false);
      this.getPage();
      if (this.currentPage === undefined) {
        return;
      }
      this.displayPage();
    },
    closeMenu() {
      this.hidePage();
      this.$emit("close");
    },
    displayPage() {
      this.currentPage.$data.display = true;
    },
    hidePage(history = true) {
      if (history) this.history.push(this.pageNumber);
      this.currentPage.$data.display = false;
    },
    getPage() {
      let pages = this.$children.filter((child) => {
        return child.$vnode.componentOptions.tag === "ContextMenuPage";
      });
      this.currentPage = pages.find((page) => page.pageNumber == this.pageNumber);
    },
  },
  computed: {
    pageReady() {
      if (this.currentPage != null) {
        return this.currentPage.$data.ready;
      }
      return false;
    },
  },
  watch: {
    pageReady: function() {
      if (this.pageReady) {
        this.$emit("updated");
      }
    },
  },
  template: `
        <div @click.stop="() => {}"><slot v-bind:next="nextPage" v-bind:prev="prevPage" v-bind:close="closeMenu"></slot></div>
    `,
};

export const ContextMenuPage = {
  name: "ContextMenuPage",
  props: {
    pageNumber: { type: Number, default: 0 },
  },
  data: () => ({
    display: false,
    ready: false,
  }),
  beforeUpdate() {
    this.ready = false;
  },
  updated() {
    this.ready = true;
  },
  template: `
        <div v-if="display"><slot v-bind:page="pageNumber"></slot></div>
    `,
};

export default ContextMenu;

<template>
  <div class="wrapper">
      <div class="dashboard-head">
        <div class="row">
          <div class="col-12 col-sm-7 col-md-9">
            <h1><strong>Talents</strong> Dashboard</h1>
          </div>
        </div>
        <base-error v-if="loaded && error" class="text-center w-100"
        >Es ist etwas <strong>schief</strong> gelaufen. <br/>
          Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
          <a href="mailto:support@entwicklerheld.de">Support</a></base-error
        >

      </div>
      <CCandidateTable :company-id="companyId"/>
    <CCandidateDetailsSidebar/>
    <svg
      class="top-wave"
      width="1140"
      height="177"
      viewBox="0 0 1140 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M241.725 135.691L91.4325 158.3C43.3002 165.54 0 128.266 0 79.5926C0 35.6348 35.6348 7.46465e-06 79.5925 1.66728e-05L1140 0.000238803V175.125L1027.13 144.842L1002.45 138.208C932.619 119.439 897.703 110.054 861.92 109.416C826.137 108.778 790.909 116.912 720.452 133.18L594.144 162.343C551.762 172.128 530.571 177.021 509.014 176.99C487.457 176.96 466.28 172.007 423.926 162.101L385.348 153.079L318.384 138.297L318.384 138.296C314.341 137.404 312.319 136.958 310.303 136.559C290.413 132.622 270.017 131.929 249.905 134.506C247.867 134.767 245.82 135.075 241.727 135.691L241.725 135.691Z"
        fill="#F1F1F4"
      />
      <path
        d="M241.725 135.691L91.4325 158.3C43.3002 165.54 0 128.266 0 79.5926C0 35.6348 35.6348 7.46465e-06 79.5925 1.66728e-05L1140 0.000238803V175.125L1027.13 144.842L1002.45 138.208C932.619 119.439 897.703 110.054 861.92 109.416C826.137 108.778 790.909 116.912 720.452 133.18L594.144 162.343C551.762 172.128 530.571 177.021 509.014 176.99C487.457 176.96 466.28 172.007 423.926 162.101L385.348 153.079L318.384 138.297L318.384 138.296C314.341 137.404 312.319 136.958 310.303 136.559C290.413 132.622 270.017 131.929 249.905 134.506C247.867 134.767 245.82 135.075 241.727 135.691L241.725 135.691Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="51.5996"
          y1="84.8949"
          x2="994.34"
          y2="31.3886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fafafa"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import BaseError from "@/components/base/BaseError";
import {eventBus} from "@/eventbus";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CCandidateTable from "@/components/candidates/CCandidateTable";
import CCandidateDetailsSidebar from "@/components/candidates/CCandidateDetailsSidebar";
import { services } from "@/main";
import { EmployeeByUserIdResult } from "@/services/company/CompanyServiceResponses";
import { Candidates } from "@/services/interact/InteractsServiceResponses";
import { SponsoredChallengesResult } from "@/services/company/CompanyServiceResponses";
import { ChallengeList } from "@/services/task-management/TaskManagementServiceResponses";
import {GetChannelsResult, GetJobOfferIdsResult} from "@/services/message/MessageServiceResponses";

export default {
  name: "CCandidatesDashboard",
  components: {
    BaseError,
    CCandidateTable,
    CCandidateDetailsSidebar,
  },
  mixins: [AuthenticatedMixin],
  data() {
    return {
      currentInteractJobOfferId: "",
      employeeByUserIdRequest: new EmployeeByUserIdResult(),
      sponsoredChallengesRequest: new SponsoredChallengesResult(),
      detailsForSponsoredChallengesRequest: new ChallengeList(),
      candidatesRequest: new Candidates(),
      getChannelsRequest: new GetChannelsResult(),
      jobOfferIdsRequest: new GetJobOfferIdsResult(),
      sponsoredChallenges: [],
      hasNoSponsoredChallenges: false,
    };
  },
  computed: {
    loaded() {
      return this.employeeByUserIdRequest.loaded;
    },
    error() {
      return this.employeeByUserIdRequest.error;
    },
    companyId: function () {
      if (this.employeeByUserIdRequest.loaded && !this.employeeByUserIdRequest.error) {
        return this.employeeByUserIdRequest.apiData.company.id;
      }
      return "";
    },
  },
  watch: {
    "employeeByUserIdRequest.loaded": function () {
      if (!this.employeeByUserIdRequest.hasLoaded()) {
        return;
      }
      this.candidatesRequest = services.interactsService.getAllCandidatesForCompany(this.companyId);
      eventBus.$emit("candidatesChanged", {
        candidatesRequest: this.candidatesRequest,
        interactJobOfferId: this.currentInteractJobOfferId,
      });
    },
    "sponsoredChallengesRequest.loaded": function () {
      if (this.sponsoredChallengesRequest.hasLoaded()) {
        if (this.sponsoredChallengesRequest.apiData.length !== 0) {
          let sponsoredChallengeIds = this.sponsoredChallengesRequest.apiData.map(
            (challenge) => challenge.challenge_id
          );
          this.detailsForSponsoredChallengesRequest = services.taskManagementService.getDataOfMultipleChallenges(
            sponsoredChallengeIds
          );
        } else {
          this.hasNoSponsoredChallenges = true;
        }
      }
    },
    "detailsForSponsoredChallengesRequest.loaded": function () {
      if (this.detailsForSponsoredChallengesRequest.hasLoaded()) {
        this.sponsoredChallenges = this.detailsForSponsoredChallengesRequest.apiData;
        this.hasNoSponsoredChallenges = this.sponsoredChallenges.length === 0;
      }
    },
  },
  created() {
    eventBus.$on("newChannelMessage", () => {
      this.getChannelsRequest = services.messageService.getChannels();
    });

    this.sponsoredChallenges = false;
    this.getChannelsRequest = services.messageService.getChannels();
    this.sponsoredChallengesRequest = services.companyService.getSponsoredChallenges();
    this.employeeByUserIdRequest = services.companyService.getEmployeeByUserId();
  },
  beforeDestroy() {
    eventBus.$off("newChannelMessage");
  },
};
</script>

<style lang="scss" scoped>
.top-wave {
  position: absolute;
  top: -80px;
  right: -20px;
  z-index: 0;
  max-width: 80%;
}

.dashboard-head {
  background-color: #fafafa;
  margin: 0;
  padding: 0.5rem 0 0.5rem 1rem;
}

.dashboard-head h1 {
  white-space: nowrap;
}

@media (max-width: 845px) {
  .dashboard-head {
    padding-left: 0.5rem;
  }
}

.dashboard-head h1 {
  font-family: Oswald, sans-serif;
  color: #606266;
  font-size: 2.5rem;
  letter-spacing: 2px;
  margin-bottom: 2.5rem;
}

@media (max-width: 540px) {
  .dashboard-head h1 {
    font-size: 1.8rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 575px) {
  .challenge-col {
    margin-bottom: 1rem;
    margin-top: -1rem;
  }
}

.challenge-button {
  text-transform: uppercase;
  font-size: 0.9rem;
  background: linear-gradient(180deg, #fabc3c, #facc6b);
  border: none;
  border-radius: 10px;
  box-shadow: 7px 6px 15px -4px rgba(202, 159, 71, 0.25);
  transition: all 0.5s ease-in-out;
  padding: 0.75rem 1rem;
}

.challenge-button a {
  color: white;
  text-decoration: none;
}

.challenge-button:hover {
  box-shadow: 7px 6px 15px -4px rgba(202, 159, 71, 0.8);
}

.dashboard-head .vacancies {
  display: flex;
}

.dashboard-head .vacancies li {
  cursor: pointer;
  padding: 0;
}

.dashboard-head .sponsored-challenges {
  display: flex;
}

.dashboard-head .sponsored-challenges li {
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: left center;
  display: inline-block;
  padding: 2px 5px 2px 40px;
  border: 2px solid #ffdd09;
  margin: 2px;
  border-radius: 7px;
  cursor: default;
}

.nav-link {
  padding: 0.5rem 1rem;
}

.nav-item {
  font-weight: 550;
  color: #606266;
  //border-bottom: 0.5px solid #dcdbdc;
  cursor: pointer;
}

.dropdown-item {
  color: #606266;
}

.challenges-nav-link {
  color: #606266;
}

.vacancies {
  margin-bottom: 1rem;
}

.dropdown-small {
  display: none;
}

.dropdown-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 20rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
  overflow-y: auto;
  max-height: 30rem;
  min-height: 6rem;
  width: 24rem;
  overflow-x: hidden;
}

.nav-item.current,
.nav-item.current:hover {
  border-bottom: 3px solid #539588;
  color: #539588;
  cursor: default;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.nav-item:hover {
  border-bottom: 3px solid #5395884d;
}

@media (max-width: 768px) {
  .dashboard-head .vacancies {
    display: none;
  }
  .dropdown-small {
    display: inline-block;
  }
  .dropdown-small .dropdown-menu {
    font-size: 1rem;
    width: 20rem !important;
  }
  .dropdown-small .dropdown-menu .dropdown-item {
    padding: 0;
  }
  .nav-item-small {
    font-size: 0.9rem;
    font-weight: 400;
    border-bottom: 0.5px solid #dcdbdc;
  }
}
</style>

<template>
  <div class="content">
    <base-error v-if="resumeRequest.loaded && resumeRequest.error" class="text-center w-100"
      >Es ist etwas <strong>schief</strong> gelaufen. <br />
      Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
      <a href="mailto:support@entwicklerheld.de">Support</a></base-error
    >

    <table class="details-general-table table">
      <tbody>
        <tr>
          <td class="label">Eingang</td>
          <td class="description">
            <span v-if="createdDateStringWords"> {{ createdDateStringWords }} / </span>
            {{ createdDateString }}
          </td>
        </tr>
        <tr>
          <td class="label">Region</td>
          <td class="description">{{ candidate.region }}</td>
        </tr>
        <tr>
          <td class="label">Arbeitgeber</td>
          <td class="description">
            <ul v-if="resumeRequest.hasLoaded() && workOrEducation.length !== 0">
              <li v-for="(item, index) in workOrEducation" :key="'item' + index">
                {{ item }}
              </li>
            </ul>
            <span v-if="resumeRequest.loaded && workOrEducation.length === 0" class="description"
              >keine Einträge vorhanden</span
            >
            <content-loader v-if="!resumeRequest.loaded" :width="480" :height="45" style="width: 200px">
              <rect width="194" height="45" fill="#C4C4C4" />
            </content-loader>
          </td>
        </tr>
        <tr>
          <td class="label">Skills</td>
          <td class="description">
            <div v-if="resumeRequest.hasLoaded() && candidateSkills.length !== 0" class="skills">
              <span
                v-for="(skill, skillIndex) of candidateSkills"
                :key="'skill' + skillIndex"
                class="skill-pill-wrapper"
              >
                <div class="skill-pills" :class="'bg-' + skill.name.toLocaleLowerCase()">
                  <i class="fa fa-code"></i>
                  <span class="skill-label">
                    {{ getLanguageName(skill.name).toUpperCase() }}
                    <span v-if="skill.keywords.includes('verified')"><i class="fa fa-check"></i></span>
                  </span>
                </div>
              </span>
            </div>
            <span v-if="resumeRequest.loaded && candidateSkills.length === 0" class="description">
              keine Skills verfügbar</span
            >
            <content-loader v-if="!resumeRequest.loaded" :width="480" :height="45" style="width: 300px">
              <rect width="124" height="45" fill="#C4C4C4" />
              <rect x="145" width="194" height="45" fill="#C4C4C4" />
            </content-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import BaseError from "@/components/base/BaseError";
import { services } from "@/main";
import { ContentLoader } from "vue-content-loader";
import { ResumeResult } from "@/services/resume/ResumeServiceResponses";
import { getLanguageName } from "@/programminglanguages";

export default {
  name: "PCandidateDetailGeneral",
  components: {
    ContentLoader,
    BaseError,
  },
  props: {
    candidate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resumeRequest: new ResumeResult(),
      getLanguageName: getLanguageName,
    };
  },
  computed: {
    workOrEducation: function() {
      let items = [];

      if (this.resumeRequest.hasLoaded()) {
        if (this.resumeRequest.apiData.work.length !== 0) {
          let lastWork = this.resumeRequest.apiData.work.slice(-1)[0];
          items.push(lastWork.name);
        } else if (this.resumeRequest.apiData.education.length !== 0) {
          let lastEducation = this.resumeRequest.apiData.education.slice(-1)[0];
          items.push(lastEducation.institution);
        }
      }

      return items;
    },
    candidateSkills() {
      if (this.resumeRequest.hasLoaded()) {
        return this.resumeRequest.apiData.skills;
      }

      return [];
    },
    createdDateString: function() {
      moment.locale("de");
      return moment(this.candidate.created).format("DD.MM.YYYY");
    },
    createdDateStringWords: function() {
      moment.locale("de");
      if (moment(this.candidate.created).isSame(moment(), "year")) {
        return moment(this.candidate.created).fromNow();
      }
      return null;
    },
  },
  watch: {
    candidate: function() {
      this.requestResumeByAccessId();
    },
  },
  mounted() {
    this.requestResumeByAccessId();
  },
  methods: {
    requestResumeByAccessId: function() {
      if (this.candidate) {
        this.resumeRequest = services.resumeService.getResumeByAccessId(this.candidate.jobRequestIds[0]);
      }
    },
    showLevel: function(level) {
      return !String(level).endsWith("%") ? `${level}%` : level;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 1rem 1.5rem;
}
.section {
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  color: #565656;
  font-size: 1rem;

  .label {
    text-transform: uppercase;
    margin-right: 2rem;
    width: 20%;
    font-weight: bold;
    display: block;
  }
  .description {
    margin-right: 0.6rem;
    padding: 0.4rem 0.6rem;
  }
}
.details-general-table {
  font-size: 1rem;
  color: #606266;
}
.table th,
.table td {
  border-top: none;
  padding-top: 0;
}
.label {
  text-transform: uppercase;
  font-weight: bold;
  display: block;
}
.description {
  width: 80%;
}
.skills {
  display: flex;
  flex-wrap: wrap;

  .skill-pill {
    margin-right: 0.6rem;
    background-color: #f5f5f5;
    color: #5d5d5d;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0.4rem 0.6rem;
    margin-bottom: 0.4rem;
  }
}

.skill-pill-wrapper {
  position: relative;
  display: inline-block;
}

.skill-pills {
  font-family: "Source Code Pro", sans-serif;
  color: white;
  overflow: hidden;
  padding: 3px 20px;
  border-radius: 100px;
  background-color: #549688;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  line-height: 1.6;
}

.skill-pills > .fa {
  position: absolute;
  bottom: -10%;
  left: -5%;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.15);
}

.skill-label {
  font-size: 12px;
}

.bg-java {
  background-color: #fa983a !important;
  color: white !important;
}
.bg-javascript {
  background-color: #f6b93b !important;
  color: white !important;
}
.bg-csharp {
  background-color: #38ada9 !important;
  color: white !important;
}
.bg-cpp {
  background-color: #e55039 !important;
  color: white !important;
}
.bg-php {
  background-color: #6a89cc !important;
  color: white !important;
}

.bg-python {
  background-color: #3c6382 !important;
  color: white !important;
}

.bg-dockerfile {
  background-color: #2980b9 !important;
  color: white !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.userIsChatMember && !_vm.isJoiningCurrentChannel)?_c('div',[_c('div',{ref:"menu"},[(_vm.menu)?_c('ChatFooterContextMenu',{directives:[{name:"closable",rawName:"v-closable",value:({
          exclude: ['messageBar', 'menu'],
          handler: 'closeMenu',
        }),expression:"{\n          exclude: ['messageBar', 'menu'],\n          handler: 'closeMenu',\n        }"}],ref:"contextMenu",staticClass:"menu-wrapper",attrs:{"on-finish":_vm.contextFinished,"channel-details":_vm.channelDetails,"max-width":parseInt(_vm.$refs.messageBar.clientWidth),"resume-id":_vm.resumeId}}):_vm._e()],1),(_vm.contextResults.size > 0)?_c('div',{staticClass:"context-result",style:({ width: _vm.$refs.messageBar.clientWidth + 'px' })},_vm._l((_vm.contextResults),function(context,index){return _c('div',{key:index,staticClass:"menu-item-wrapper"},[_c(context.component.component,_vm._b({tag:"component",attrs:{"send":_vm.sendMessageEvent,"on-send-finished":_vm.send}},'component',context.component.static,false)),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.removeContextPart(context.message)}}},[_c('i',{staticClass:"dev-x",staticStyle:{"font-size":"20px"},attrs:{"stroke-width":"1.5","color":"#A01616"}})])],1)}),0):_vm._e(),_c('div',{ref:"messageBar",staticClass:"write"},[_c('div',{staticClass:"context-options",class:{ 'close-menu': _vm.menu, disabled: _vm.disabled },on:{"click":function($event){return _vm.showMenu()}}},[_c('i',{staticClass:"dev-plus",staticStyle:{"font-size":"20px"}})]),_c('div',{staticClass:"message",attrs:{"data-replicated-value":_vm.value}},[_c('textarea',{ref:"message",attrs:{"rows":"1","placeholder":_vm.disabled ? 'Sie können noch keine Nachricht schreiben' : 'Nachricht',"onInput":"this.parentNode.dataset.replicatedValue = this.value;","disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.hideMenu()},"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":_vm.onTyping}})]),_c('div',{staticClass:"send",class:{
          fly: _vm.fly,
          disabled: (_vm.value === null || (_vm.value !== null && _vm.value.length == 0)) && _vm.contextResults.size == 0,
        },on:{"mousedown":function($event){return _vm.startSend()}}},[_c('i',{staticClass:"dev-send-plane",staticStyle:{"font-size":"20px"}})])])]):_vm._e(),(!_vm.userIsChatMember)?_c('div',{staticClass:"footer-bg"},[(!_vm.showChannelJoinConfirmation)?_c('button',{staticClass:"btn btn-secondary d-flex justify-content-center",attrs:{"disabled":_vm.isJoiningCurrentChannel},on:{"click":function($event){$event.preventDefault();return _vm.joinChannel($event)}}},[(_vm.isJoiningCurrentChannel)?_c('i',{staticClass:"fa fa-spinner fa-pulse fa-1x"}):_vm._e(),_vm._v(" Chat beitreten ")]):_vm._e(),(_vm.showChannelJoinConfirmation)?_c('div',[_c('p',[_vm._v(" Wollen Sie dem Chat wirklich beitreten? ")]),_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-primary justify-content-center submit",on:{"click":function($event){$event.preventDefault();return _vm.confirmJoinChannel($event, true)}}},[_vm._v(" Ja ")]),_c('button',{staticClass:"btn btn-secondary justify-content-center",on:{"click":function($event){$event.preventDefault();return _vm.confirmJoinChannel($event, false)}}},[_vm._v(" Abbrechen ")])])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
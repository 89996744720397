<template>
  <div>
    <div
      v-for="programmingTechnology of technologyList"
      :key="programmingTechnology.id + programmingTechnology.language"
      class="challenge-group-languages-wrapper"
    >
      <div
        :key="programmingTechnology.id + programmingTechnology.language"
        class="challenge-group-languages"
        :class="{
          'bg-python': programmingTechnology.language.toLocaleLowerCase() === 'python',
          'bg-dockerfile': programmingTechnology.language.toLocaleLowerCase() === 'dockerfile',
          'bg-java': programmingTechnology.language.toLocaleLowerCase() === 'java',
          'bg-javascript': programmingTechnology.language.toLocaleLowerCase() === 'javascript',
          'bg-csharp': programmingTechnology.language.toLocaleLowerCase() === 'csharp',
          'bg-cpp': programmingTechnology.language.toLocaleLowerCase() === 'cpp',
          'bg-php': programmingTechnology.language.toLocaleLowerCase() === 'php',
          linked: hasAction,
        }"
        @click="(e) => clicked(e, programmingTechnology)"
      >
        <i class="fa fa-code"></i>
        <span class="technology-label">{{ getTechnologyDisplayName(programmingTechnology).toUpperCase() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { LANGUAGES } from "../../../../programminglanguages";

export default {
  name: "LanguageBubble",
  components: {},
  props: {
    technologyList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showLanguage: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasAction: { type: Boolean, default: true },
  },
  methods: {
    getTechnologyDisplayName(technology) {
      let programmingLanguage = technology.language.toLocaleLowerCase();
      if (programmingLanguage === "") {
        return this.name;
      }
      if (technology.name === "" || this.showLanguage) {
        return LANGUAGES[programmingLanguage];
      }
      return LANGUAGES[programmingLanguage] + " (" + technology.name + ")";
    },
    clicked(event, programmingTechnology) {
      if (this.hasAction) {
        event.stopPropagation();
        this.$emit("click", programmingTechnology);
      }
    },
  },
};
</script>
<style scoped>
.linked:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.linked {
  cursor: pointer;
}

.challenge-group-languages-wrapper {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
}

.challenge-group-languages {
  font-family: "Source Code Pro", sans-serif;
  color: white;
  overflow: hidden;
  padding: 3px 20px;
  margin-bottom: 2px;
  border-radius: 100px;
  background-color: grey;
  margin-right: 1rem;
  line-height: 1.6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.challenge-group-languages > .fa {
  position: absolute;
  transform: translate(-24px, 0px);
  font-size: 28px;
  color: rgba(255, 255, 255, 0.15);
}

.technology-label {
  white-space: nowrap;
}

.isNotMobile#java:hover,
.bg-java {
  background-color: #fa983a !important;
  color: white !important;
}

.isNotMobile#javascript:hover,
.bg-javascript {
  background-color: #f6b93b !important;
  color: white !important;
}

.isNotMobile#csharp:hover,
.bg-csharp {
  background-color: #38ada9 !important;
  color: white !important;
}

.isNotMobile#cpp:hover,
.bg-cpp {
  background-color: #e55039 !important;
  color: white !important;
}

.isNotMobile#php:hover,
.bg-php {
  background-color: #6a89cc !important;
  color: white !important;
}

.isNotMobile#python:hover,
.bg-python {
  background-color: #3c6382 !important;
  color: white !important;
}

.isNotMobile#dockerfile:hover,
.bg-dockerfile {
  background-color: #2980b9 !important;
  color: white !important;
}
</style>

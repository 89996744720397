<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <textarea
          style="background: #fafafa"
          ref="noteInput"
          v-model="candidate.note.content"
          :disabled="!requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error"
          :class="{ edited: noteEdited }"
          placeholder="Notizen eingeben..."
          @focus="onFocusNoteInput"
        >
        </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button
          :disabled="
            (!requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error) ||
              !candidate.tempNote
          "
          class="btn btn-cancel"
          @click="resetNote()"
          ><i class="fa fa-times" />zurücksetzen</button
        >
        <button
          :disabled="
            (!requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error) ||
              (candidate.tempNote && !noteEdited)
          "
          class="btn btn-okay"
          @click="submitNote()"
          ><i class="fa fa-check" />speichern</button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <div v-if="!requestObject.setCandidateNoteRequest.loaded && !requestObject.setCandidateNoteRequest.error">
          Notizen werden gespeichert...
          <i class="fa fa-circle-o-notch fa-spin loading-spinner"></i>
        </div>
        <base-error
          v-if="requestObject.setCandidateNoteRequest.loaded && requestObject.setCandidateNoteRequest.error"
          class="text-center w-100"
          >Es ist etwas <strong>schief</strong> gelaufen. <br />
          Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
          <a href="mailto:support@entwicklerheld.de">Support</a></base-error
        >
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventbus";
import BaseError from "@/components/base/BaseError";
import { truncate } from "lodash";

export default {
  name: "CCandidateDetailNote",
  components: {
    BaseError,
  },
  props: {
    candidate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      requestObject: {
        setCandidateNoteRequest: { loaded: true, error: null },
      },
    };
  },
  computed: {
    noteEdited: function() {
      return this.candidate.tempNote !== this.candidate.note.content;
    },
  },
  watch: {
    candidate: function() {
      this.requestObject.setCandidateNoteRequest = { loaded: true, error: null };
    },
    "requestObject.setCandidateNoteRequest.loaded": function() {
      if (this.requestObject.setCandidateNoteRequest.loaded) {
        if (!this.requestObject.setCandidateNoteRequest.error) {
          this.candidate.tempNote = null;
          this.closeNoteEditor();
        }
      }
    },
  },
  methods: {
    closeNoteEditor: function() {
      this.candidate.previewNote = truncate(this.candidate.note.content);
    },
    onFocusNoteInput: function() {
      if (!this.candidate.tempNote) {
        // save temporary note for reset
        this.candidate.tempNote = this.candidate.note.content;
      }
    },
    resetNote: function() {
      this.candidate.note.content = this.candidate.tempNote;
      this.candidate.previewNote = truncate(this.candidate.note.content);
    },
    submitNote: function() {
      if (this.candidate.tempNote !== this.candidate.note.content) {
        eventBus.$emit("candidateNoteChanged", { candidate: this.candidate, response: this.requestObject });
      } else {
        this.closeNoteEditor();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  background: none;
  border-color: transparent;
  padding: 1rem;
  resize: none;
  width: 100%;
  color: #606266;
  height: 100%;
  min-height: 20rem;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  border-radius: 10px;

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
  }
  &::placeholder {
    color: #dcdbdc;
  }
  &:focus,
  &.edited {
    background-color: #fff;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: $eh-green;
    overflow: auto;
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #a9cac4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #539588;
  }
}
.btn {
  font-size: 0.8rem;
  text-transform: uppercase;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: auto;
  transition: all 0.25s ease-in-out;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  color: #606266;
}

.fa {
  margin-right: 0.5rem;
}
button.btn-cancel {
  border: 3px solid #e74c3c;
  margin-right: 0;
}
button.btn-cancel:hover {
  color: #fff;
  background-color: #e74c3c;
}

button.btn-okay {
  border: 3px solid #539588;
  margin-right: 0;
}
button.btn-okay:hover {
  background-color: #539588;
  color: #fff;
}
</style>

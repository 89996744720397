<template>
  <div v-if="candidate" class="content">
    <div class="row">
      <div class="col-12">
        <span class="description"><strong>Das Lebenslauf-Feature wird zur Zeit überarbeitet. In der Zwischenzeit können Sie die Nutzer im Chat nach einem Lebenslauf fragen. Wir entschuldigen uns für die Umstände.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PCandidateDetailCV",
  components: {},
  props: {
    candidate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  margin: 1rem 1.5rem;
}
</style>

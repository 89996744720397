<template>
  <div class="dashboard">
    <base-error v-if="loaded && error" class="text-center w-100"
    >Es ist etwas <strong>schief</strong> gelaufen. <br/>
      Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
      <a href="mailto:support@entwicklerheld.de">Support</a></base-error
    >

    <content-loader v-if="!loaded && !error">
      <rect x="0" y="0" rx="2" ry="2" width="200" height="20"/>
    </content-loader>

    <div v-if="loaded && !error" class="table-wrapper">
      <!-- new filter start -->
      <div>
        <div class="filter-row row">
          <div
            v-for="filterData in filterSettings"
            v-show="!filterData.disabled"
            :key="'filter' + filterData.index"
            class="filter-col col-6 col-md-4 col-lg-3"
          >
            <div class="filter-wrapper">
              <div
                :class="{ active: activeFilterIndex === filterData.index }"
                class="row filter-display"
                @click="setActiveFilterIndex(filterData.index)"
              >
                <div class="icon col-4">
                  <span :class="filterData.iconClass"></span>
                </div>
                <div class="filter-info col-8">
                  <div class="row">
                    <div class="col-12 ">
                      <span class="counter">{{ filterData.counter }}</span>
                    </div>
                    <div v-if="filterData.index !== 5" class="col-12 align-self-center name-wrapper">
                      <div class="name">{{ filterData.name }}</div>
                    </div>
                    <div v-if="filterData.index === 5" class="col-12 align-self-center name-wrapper">
                      <div class="name">neue Nachricht<span v-if="filterData.counter !== 1">en</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- new filter end -->
        <div class="row">
          <div class="search-wrapper col-12 col-md-6 col-xl-5">
            <div class="search-icon">
              <i class="fa fa-search"></i>
            </div>
            <input
              id="searchInput"
              v-model="searchTerm"
              class="search"
              type="text"
              placeholder="In dieser Tabelle suchen"
            />
          </div>
        </div>

        <div class="row options-row">
          <div class="col-7">
            <div class="actions" :class="{ enabled: actionsEnabled }">
              Aktionen:
              <PArchiveButton event-name="archiveSelectedCandidates"/>
            </div>
          </div>
          <div class="col-5 align-self-end">
            <PMoreOptionsButton :option-data="moreOptions"/>
          </div>
        </div>
        <vue-good-table
          ref="candidates-table"
          :key="forceUpdateIndex"
          :columns="columns"
          :rows="candidatesList"
          :row-style-class="rowStyleClass"
          :line-numbers="lineNumbers"
          :fixed-header="fixedHeader"
          :sort-options="sortOptions"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          skipDiacritics: true,
        }"
          :select-options="selectOptions"
          style-class="vgt-table"
          compact-mode
          @on-selected-rows-change="selectionChanged"
          @on-cell-click="onCellClick"
        >
          <div slot="emptystate">
            Noch keine Talents vorhanden
          </div>
          <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'isArchived'">
            <div v-if="!props.row.seen && !props.row.isArchived" class="new">NEU</div>
            <i v-if="props.row.isArchived" class="fa fa-archive"></i>
          </span>
            <span v-if="props.column.field === 'img'">
            <div class="profile-picture" :style="'background-image: url(\'' + props.row.anonymizedPicture + '\')'">
              <div v-if="props.row.sponsoredChallengesSolved.length !== 0" class="sponsoredChallengesSolved">
                <span
                  v-b-tooltip.hover.top.noninteractive.d500="'Gesponserte Challenge gelöst'"
                  class="sponsoredChallengeSolved"
                  :style="
                    'background-image: url(https://res.cloudinary.com/dspi5zyaa/image/upload/v1619766878/eh-platform/icons/smashicons_Development_laptop-coding-icon-4-yellow_256_1.png)'
                  "
                ></span>
              </div>
              <!-- TODO: Think about companies which offer multiple sponsored challenges. Actually this is only used when the user not solved any sponsored challenge of the company -->
              <div
                v-if="interactJobOfferId === 'ALL_WITHOUT_JOB_SELECTION' && props.row.unsolvedSponsoredChallenge && props.row.sponsoredChallengesSolved.length === 0"
                class="acceptedButNotSolvedChallenges">
                <span
                  v-b-tooltip.hover.top.noninteractive.d500="'Gesponserte Challenge angenommen aber noch nicht gelöst'"
                  class="acceptedButNotSolvedChallenge"
                  :style="
                    'background-image: url(https://res.cloudinary.com/dspi5zyaa/image/upload/v1619766878/eh-platform/icons/smashicons_Development_laptop-coding-icon-4-yellow_256_1.png)'
                  "
                ></span>
              </div>
            </div>
          </span>
            <span v-if="props.column.field === 'anonymizedUserName'">
            <strong>{{ props.row.anonymizedUserName }}</strong>
          </span>
            <span v-if="props.column.field === 'region'">
            <span>{{ props.row.region }}</span>
          </span>
            <span v-if="props.column.field === 'created'">
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </span>
            <span v-if="props.column.field === 'lastMessageDatetime'">
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </span>
            <span v-if="props.column.field === 'itWorkExperience'">
            <div v-if="props.row.itWorkExperience === 0 || props.row.itWorkExperience === null">
              {{ props.row.profession }}
            </div>
            <div v-if="props.row.itWorkExperience === 1">
              {{ props.row.profession }},<br/> 1 Jahr Erfahrung
            </div>
            <div v-if="props.row.itWorkExperience >= 2">
              {{ props.row.profession }},<br/> {{ props.row.itWorkExperience }} Jahre Erfahrung
            </div>
          </span>
            <span v-if="props.column.field === 'note.content'" class="shortcomment">
            <div class="d-flex align-items-center">
              <span class="">
                {{ props.row.previewNote }}
              </span>
              <span class="edit-button ml-auto">
                <button type="button" class="btn edit-btn" @click.stop="editNote(props.row)">
                  <div>
                    <i class="fa fa-pencil"></i>
                  </div>
                </button>
              </span>
            </div>
          </span>
            <span v-if="props.column.field === 'chatState'">
            <CChatButton :candidate="props.row" :interact-job-offer-id="interactJobOfferId"/>
          </span>
            <span v-if="props.column.field === 'isBookmarked'">
            <PBookmarkButton :candidate="props.row"/>
          </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <PCandidateNoteEditorModal ref="noteEditor"/>
    <ScrollToTopButton/>
    <div v-if="timeToUpdateCandidates"></div>
  </div>
</template>

<script>
import BaseError from "@/components/base/BaseError";
import {services} from "@/main";
import {eventBus} from "@/eventbus";
import {ContentLoader} from "vue-content-loader";
import {VueGoodTable} from "vue-good-table";
import {Candidates} from "@/services/interact/InteractsServiceResponses";
import {SponsoredChallengesResult} from "@/services/company/CompanyServiceResponses";
import {ChallengeList} from "@/services/task-management/TaskManagementServiceResponses";
import {GetChannelsResult, GetChannelIdResult} from "@/services/message/MessageServiceResponses";

import ScrollToTopButton from "@/components/base/ScrollToTopButton";
import PMoreOptionsButton from "@/components/base/PMoreOptionsButton";
import CChatButton from "@/components/candidates/CChatButton";
import PBookmarkButton from "@/components/candidates/PBookmarkButton";
import PArchiveButton from "@/components/candidates/PArchiveButton";
import PCandidateNoteEditorModal from "@/components/candidates/PCandidateNoteEditorModal";
import {truncate} from "lodash";

import "vue-good-table/dist/vue-good-table.css";

export default {
  name: "CCandidateTable",
  components: {
    BaseError,
    ContentLoader,
    VueGoodTable,
    ScrollToTopButton,
    PMoreOptionsButton,
    CChatButton,
    PBookmarkButton,
    PArchiveButton,
    PCandidateNoteEditorModal,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      socket: null,
      interactJobOfferId: "",
      candidatesList: [],
      sponsoredChallenges: [],
      searchTerm: "",
      activeCandidate: null,
      actionsEnabled: false,
      lineNumbers: false,
      fixedHeader: false,
      ignoreGetParam: false,
      activeFilterIndex: 0,
      forceUpdateIndex: 0,
      moreOptions: [
        {
          label: "auch archivierte einblenden",
          checked: false,
          eventName: "archivedFilterChanged",
          onChange: this.showHideArchived,
        },
        {
          label: "Region einblenden",
          checked: false,
          eventName: "regionFilterChanged",
          onChange: this.showHideRegion,
        },
      ],
      filterCountSubsetMode: true, // determines how chat states are counted
      // false - all chat states are exclusively counted
      // true - all chat states are counted as subsets
      filterSettings: [
        {
          index: 0,
          name: "Kontakte",
          counter: 52,
          iconClass: "fa fa-users",
          filterFn: function (chatState, filterCountSubsetMode) {
            return true;
          },
          disabled: false,
        },
        {
          index: 1,
          name: "im Gespräch",
          counter: 3,
          iconClass: "fa fa-comments",
          filterFn: function (chatState, filterCountSubsetMode) {
            return (
              (filterCountSubsetMode || !chatState.unreadMessage && chatState.unrespondedMessage) &&
              chatState.totalMessagesSent !== 0 &&
              chatState.totalMessagesOfPlatformUser !== 0
            );
          },
          disabled: false,
        },
        {
          index: 2,
          name: "nicht beantwortet",
          counter: 2,
          iconClass: "fa fa-comments-o",
          filterFn: function (chatState, filterCountSubsetMode) {
            return (
              (filterCountSubsetMode || !chatState.unreadMessage) &&
              chatState.unrespondedMessage &&
              chatState.totalMessagesSent !== 0 &&
              chatState.totalMessagesOfPlatformUser !== 0
            );
          },
          disabled: false,
        },
        {
          index: 3,
          name: "neue Nachrichten",
          counter: 2,
          iconClass: "fa fa-comments-o unread-bubble",
          filterFn: function (chatState, filterCountSubsetMode) {
            return (
              chatState.unreadMessage &&
              chatState.totalMessagesSent !== 0 &&
              chatState.totalMessagesOfPlatformUser !== 0
            );
          },
          disabled: false,
        },
        {
          index: 4,
          name: "Lesezeichen",
          counter: 7,
          iconClass: "fa fa-bookmark",
          filterFn: function (isBookmarked) {
            return isBookmarked;
          },
          disabled: false,
        },
      ],
      // column indices for more convenient access
      // must be updated on changes on columns
      archiveColumnIndex: 0,
      imageColumnIndex: 1,
      nameColumnIndex: 2,
      createdColumnIndex: 3,
      lastMessageDateTimeColumnIndex: 4,
      regionColumnIndex: 5,
      professionColumnIndex: 6,
      noteColumnIndex: 7,
      chatColumnIndex: 8,
      bookmarkColumnIndex: 9,
      columns: [
        {
          // 0
          label: "",
          field: "isArchived",
          html: true,
          hidden: false,
          sortable: false,
          filterOptions: {
            enabled: true,
            filterValue: "0",
            filterFn: this.archivedFilterFn,
          },
        },
        {
          // 1
          label: "Bild",
          field: "img",
          html: true,
          hidden: false,
          sortFn: this.challengeSort,
          thClass: "hide-on-md",
          tdClass: "hide-on-md",
          firstSortType: "desc",
        },
        {
          // 2
          label: "Name",
          field: "anonymizedUserName",
          html: true,
          hidden: false,
          tooltip: "Mit SHIFT-Klick Sortierung über mehrere Spalten",
        },
        {
          // 3
          label: "Hinzugefügt am",
          field: "created",
          html: true,
          hidden: false,
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd.MM.yyyy",
          tooltip: "Mit SHIFT-Klick Sortierung über mehrere Spalten",
        },
        {
          // 4
          label: "Letzter Kontakt",
          field: "lastMessageDatetime",
          html: true,
          hidden: false,
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          dateOutputFormat: "dd.MM.yyyy HH:mm",
          tooltip: "Mit SHIFT-Klick Sortierung über mehrere Spalten",
        },
        {
          // 5
          label: "Region",
          field: "region",
          html: true,
          hidden: true,
          tooltip: "Mit SHIFT-Klick Sortierung über mehrere Spalten",
        },
        {
          // 6
          label: "Profession",
          field: "itWorkExperience",
          html: true,
          hidden: false,
          thClass: "hide-on-md",
          tdClass: "hide-on-md",
          tooltip: "Mit SHIFT-Klick Sortierung über mehrere Spalten",
          sortFn: this.professionSort,
        },
        {
          // 7
          label: "Notizen",
          field: "note.content",
          html: true,
          sortable: false,
          thClass: "hide-on-lg text-left",
          tdClass: "hide-on-lg text-left",
        },
        {
          // 8
          label: "Chat",
          field: "chatState",
          html: true,
          hidden: false,
          sortable: false,
          filterOptions: {
            enabled: false,
            filterValue: "0",
            filterFn: this.chatFilterFn,
          },
        },
        {
          // 9
          label: "Lesezeichen",
          field: "isBookmarked",
          html: true,
          hidden: false,
          sortable: false,
          filterOptions: {
            enabled: false,
            filterValue: "0",
            filterFn: this.bookmarkFilterFn,
          },
        },
        {
          // String that contains all searchable strings
          label: "SearchString",
          field: "searchString",
          hidden: true,
        },
      ],
      sortOptions: {
        enabled: true,
        multipleColumns: true,
        initialSortBy: {field: "created", type: "desc"},
      },
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true,
      },
      getChannelsRequest: new GetChannelsResult(),
      updateChannelsRequest: new GetChannelIdResult(),
      candidatesRequest: new Candidates(),
      sponsoredChallengesRequest: new SponsoredChallengesResult(),
      detailsForSponsoredChallengesRequest: new ChallengeList(),
      getChannelIdRequest: new GetChannelIdResult(),
      updateCandidateFlagsRequest: {loaded: true, error: null},
    };
  },
  computed: {
    loaded: function () {
      return this.getChannelsRequest.loaded && this.candidatesRequest.loaded;
    },
    error: function () {
      return this.candidatesRequest.error;
    },
    timeToUpdateCandidates: function () {
      if (this.candidatesRequest.loaded &&
        !this.candidatesRequest.error &&
        this.detailsForSponsoredChallengesRequest.loaded &&
        !this.detailsForSponsoredChallengesRequest.error) {
        this.enrichCandidatesList();
        return true;
      }
      return false;
    }
  },
  watch: {
    "updateCandidateFlagsRequest.loaded": function () {
      if (this.updateCandidateFlagsRequest.loaded && this.updateCandidateFlagsRequest.data) {
        this.updateCandidateInList(this.updateCandidateFlagsRequest.data, this.updateCandidateFlagsRequest.error);
      }
    },
    "$route.query.q": {
      handler: function () {
        if (this.$route.query.q === undefined) {
          this.searchTerm = "";
        } else {
          this.searchTerm = this.$route.query.q;
        }
      },
      deep: true,
      immediate: true,
    },
    "candidatesRequest.loaded": function () {
      this.requestChannels();
    },
    "getChannelsRequest.loaded": function () {
      if (this.getChannelsRequest.loaded) {
        this.enrichCandidatesList();
        this.countFilters();
      }
      if (!this.ignoreGetParam && this.$route.query.channelId) {
        let channelId = this.$route.query.channelId;
        this.ignoreGetParam = true;
        this.preselectCandidate(channelId);
      }
    },
    "updateChannelsRequest.loaded": function () {
      if (this.updateChannelsRequest.loaded && !this.updateChannelsRequest.error) {
        this.updateChatStates(this.updateChannelsRequest.apiData);
        this.countFilters();
      }
    },
    "sponsoredChallengesRequest.loaded": function () {
      if (this.sponsoredChallengesRequest.hasLoaded()) {
        if (this.sponsoredChallengesRequest.apiData.length !== 0) {
          let sponsoredChallengeIds = this.sponsoredChallengesRequest.apiData.map(
            (challenge) => challenge.challenge_id
          );
          this.detailsForSponsoredChallengesRequest = services.taskManagementService.getDataOfMultipleChallenges(
            sponsoredChallengeIds
          );
        }
      }
    },
    "detailsForSponsoredChallengesRequest.loaded": function () {
      if (this.detailsForSponsoredChallengesRequest.hasLoaded()) {
        this.sponsoredChallenges = this.detailsForSponsoredChallengesRequest.apiData;
      }
    },
    "getChannelIdRequest.loaded": function () {
      if (this.getChannelIdRequest.loaded) {
        if (this.getChannelIdRequest.error) {
          eventBus.$emit("showChannelIdRequestError", this.getChannelIdRequest.error);
        } else {
          this.getChannelIdRequest.data.candidate.channelId = this.getChannelIdRequest.apiData.channelId;
          this.updateCandidateChannelId(this.getChannelIdRequest.data);
        }
      }
    },
  },
  mounted() {
    this.originalColumns = JSON.parse(JSON.stringify(this.columns));
    this.initEvents();
    this.requestSponsoredChallenges();
  },
  beforeDestroy() {
    this.removeEvents();
    this.removeMoreOptionsEvents();
  },
  methods: {
    initEvents: function () {
      eventBus.$on("updateEmployeeIsChannelMember", (data) => {
        this.updateEmployeeIsChannelMember(data.channelId);
      });
      eventBus.$on("closeDetailComponent", () => {
        this.unselectActiveCandidate();
      });
      eventBus.$on("openDetailComponent", (data) => {
        this.selectActiveCandidate(data.candidate);
      });
      eventBus.$on("candidatesChanged", (data) => {
        this.interactJobOfferId = data.interactJobOfferId;
        this.candidatesRequest = data.candidatesRequest;
        this.activeCandidate = null;
        this.actionsEnabled = false;

        this.columns[this.bookmarkColumnIndex].hidden = false;
        this.columns[this.archiveColumnIndex].hidden = false;
      });
      eventBus.$on("candidate_chat_state_changed", (candidate) => {
        if (candidate.chatState.unreadMessage) {
          candidate.chatState.unreadMessage = false;
          this.updateChannels();
          eventBus.$emit("newChannelMessage", {
            channelId: candidate.channelId,
            interactJobOfferId: this.interactJobOfferId
          });
        }
      });
      // ToDo: Error handling, success msg?
      eventBus.$on("candidateNoteChanged", (data) => {
        data.response.setCandidateNoteRequest = services.interactsService.setCandidateNote(
          this.companyId,
          data.candidate
        );
        this.updateCandidateNoteInList(data.candidate);
      });
      eventBus.$on("candidateStateChanged", (candidate) => {
        if (candidate.onlyCompanySelected) {
          this.updateCandidateFlagsRequest = services.interactsService.updateInterestedCandidateFlags(
            this.companyId,
            candidate
          );
        } else {
          this.updateCandidateFlagsRequest = services.interactsService.updateCandidateFlags(this.interactJobOfferId, candidate);
        }
        this.updateCandidateFlagsRequest.data = candidate;
      });
      eventBus.$on("archiveSelectedCandidates", () => {
        this.bulkArchiveCandidates();
      });
      eventBus.$on("requestCandidateChannelId", (data) => {
        let jobRequestId =
          data.candidate.jobRequestIds && data.candidate.jobRequestIds.length !== 0
            ? data.candidate.jobRequestIds[0]
            : "";
        this.getChannelIdRequest = services.messageService.getChannelId(jobRequestId, "");
        this.getChannelIdRequest.data = data;
      });
      this.initMoreOptionsEvents();
      // init notification connection events
      this.createNotificationConnection();
    },
    removeEvents: function () {
      eventBus.$off("updateEmployeeIsChannelMember");
      eventBus.$off("candidatesChanged");
      eventBus.$off("closeDetailComponent");
      eventBus.$off("openDetailComponent");
      eventBus.$off("candidate_chat_state_changed");
      eventBus.$off("candidateNoteChanged");
      eventBus.$off("candidateStateChanged");
      eventBus.$off("archiveSelectedCandidates");
      eventBus.$off("requestCandidateChannelId");
      eventBus.$off("candidateChannelEvent");
    },
    initMoreOptionsEvents: function () {
      for (let index = 0; index < this.moreOptions.length; index++) {
        let option = this.moreOptions[index];
        if (option.eventName && option.onChange) {
          eventBus.$on(option.eventName, (checked) => {
            option.onChange(checked);
          });
        }
      }
    },
    removeMoreOptionsEvents: function () {
      for (let index = 0; index < this.moreOptions.length; index++) {
        let option = this.moreOptions[index];
        if (option.eventName && option.onChange) {
          eventBus.$off(option.eventName);
        }
      }
    },
    createNotificationConnection: function () {
      const userID = services.authenticationService.getUserID();
      this.socket = services.notificationsService.getNewMessageNotificationSocket();
      let channelName = `notifications_message_${userID}_new-message`;
      eventBus.$on("candidateChannelEvent", (channelId) => {
        this.candidateChannelEvent(channelId);
      });
      this.socket.on(channelName, (data) => {
        let jsonData = JSON.parse(data);
        let channelId = jsonData.channelId;
        this.candidateChannelEvent(channelId);
      });
    },
    preselectCandidate: function (channelId) {
      const candidate = this.getCandidateByChannel(channelId);
      if (candidate) {
        if (candidate.isArchived) {
          this.showHideArchived(true);
          this.moreOptions[0].checked = true;
        }
        candidate.chatState.chatMinimized = false;
        eventBus.$emit("openDetailComponent", {
          channelId: channelId,
          candidate: candidate,
          interactJobOfferId: this.interactJobOfferId,
        });
      }
    },
    candidateChannelEvent: function (channelId) {
      if (channelId) {
        // check if channel is from a candidate of the current vacancy
        let candidateInTable = this.getCandidateByChannel(channelId);
        if (candidateInTable) {
          this.updateChannels();
        } else {
          // if candidate is not in current list, send event for the tab heads
          eventBus.$emit("newChannelMessage", {channelId: channelId, interactJobOfferId: this.interactJobOfferId});
        }
      }
    },
    updateCandidateChannelId: function (data) {
      // The candidates in the table are deepcloned.
      // Thus, changes must be updated on the original candidates.
      let candidateInTable = data.candidate;
      let candidateInList = this.getCandidateInList(candidateInTable);
      const chatMinimized = data.candidate.chatState.chatMinimized ? true : false;
      if (candidateInList) {
        candidateInList.chatState.chatMinimized = chatMinimized;
        candidateInList.channelId = candidateInTable.channelId;
      }

      candidateInTable.chatState.chatMinimized = chatMinimized;
      eventBus.$emit("openCandidateChatComponent", data);
    },
    getCandidateByChannel: function (channelId) {
      let candidateInList = this.candidatesList.find((candidate) => candidate.channelId === channelId);
      return candidateInList;
    },
    getCandidateInList: function (candidateInTable) {
      let candidateInList = null;
      if (candidateInTable.originalIndex || candidateInTable.originalIndex === 0) {
        candidateInList = this.candidatesList[candidateInTable.originalIndex];
        candidateInList.originalIndex = candidateInTable.originalIndex;
      } else {
        candidateInList = this.candidatesList.find(
          (candidate) => candidate.publicUserId === candidateInTable.publicUserId
        );
      }
      return candidateInList;
    },
    requestSponsoredChallenges: function () {
      this.sponsoredChallengesRequest = services.companyService.getSponsoredChallenges();
    },
    requestChannels: function () {
      this.getChannelsRequest = services.messageService.getChannels();
    },
    updateChannels: function () {
      this.updateChannelsRequest = services.messageService.getChannels();
    },
    updateChatStates: function (channels) {
      for (let candidate of this.candidatesList) {
        let chatState = {
          totalMessagesSent: candidate.chatState.totalMessagesSent,
          totalMessagesOfPlatformUser: candidate.chatState.totalMessagesOfPlatformUser,
          unreadMessage: candidate.chatState.unreadMessage,
        };
        this.addChatState(candidate, channels);
        if (
          chatState.totalMessagesSent !== candidate.chatState.totalMessagesSent ||
          chatState.totalMessagesOfPlatformUser !== candidate.chatState.totalMessagesOfPlatformUser ||
          chatState.unreadMessage !== candidate.chatState.unreadMessage
        ) {
          let eventName = candidate.publicUserId + "_chat_state_changed";
          eventBus.$emit(eventName, candidate.chatState);
        }
      }
    },
    enrichCandidatesList: function () {
      let requestedCandidatesList = this.candidatesRequest.apiData;
      let enrichedCandidatesList = [];
      for (let candidate of requestedCandidatesList) {
        this.addChatState(candidate, this.getChannelsRequest.apiData);
        this.addSponsoredChallengeInfo(candidate);

        this.addJobOfferInfo(candidate);
        candidate.noteEditing = false;
        candidate.isActive = false;
        candidate.vgtSelected = false;
        candidate.previewNote = truncate(candidate.note.content);
        candidate.created = candidate.created.split("T")[0];
        enrichedCandidatesList.push(candidate);
      }
      this.candidatesList = enrichedCandidatesList;
    },
    addChatState: function (candidate, channels) {
      let totalMessagesSent = 0;
      let totalMessagesOfPlatformUser = 0;
      let unreadMessage = false;
      let unrespondedMessage = false;
      let isEmployeeChannelMember = false;

      if (channels) {
        let candidateChannel = channels.find((channel) =>
          channel.sources.jobRequestIds.some((jobRequestId) => candidate.jobRequestIds.includes(jobRequestId))
        );
        if (candidateChannel) {
          totalMessagesSent = candidateChannel.totalMessagesSent;
          totalMessagesOfPlatformUser = candidateChannel.totalMessagesOfPlatformUser;
          unreadMessage = candidateChannel.unreadMessage;
          unrespondedMessage = candidateChannel.unrespondedMessage;
          candidate.lastMessageDatetime = new Date(candidateChannel.lastMessageDatetime);
          candidate.channelId = candidateChannel.id;
          isEmployeeChannelMember = candidateChannel.isEmployeeChannelMember ? true : false;
        }
      }

      let chatMinimized = true;

      if (candidate.chatState) {
        chatMinimized = candidate.chatState.chatMinimized;
      } else {
        candidate.chatState = {};
      }

      candidate.chatState.totalMessagesSent = totalMessagesSent;
      candidate.chatState.totalMessagesOfPlatformUser = totalMessagesOfPlatformUser;
      candidate.chatState.unreadMessage = unreadMessage;
      candidate.chatState.unrespondedMessage = unrespondedMessage;
      candidate.chatState.chatMinimized = chatMinimized;
      candidate.chatState.isEmployeeChannelMember = isEmployeeChannelMember;
    },
    addSponsoredChallengeInfo: function (candidate) {
      candidate.sponsoredChallengesSolved = [];
      for (let challenge of candidate.solvedChallenges) {
        let solvedChallenge = this.sponsoredChallenges.find(
          (sponsoredChallenge) => sponsoredChallenge.id === challenge.challengeId
        );
        if (solvedChallenge) {
          candidate.sponsoredChallengesSolved.push({
            challengeId: solvedChallenge.id,
            acceptedChallengeUuid: challenge.acceptedChallengeUuid,
            title: solvedChallenge.title,
            icon: solvedChallenge.icon,
            technology: solvedChallenge.technology,
          });
        }
      }

      /** TODO: Think about companies which offer multiple sponsored challenges. Actually this is only used when the user not solved any sponsored challenge of the company */
      for (const challengeId of candidate.unsolvedChallengeIds) {
        let unsolvedChallenge = this.sponsoredChallenges.find((sponsoredChallenge) => sponsoredChallenge.id === challengeId);
        if (unsolvedChallenge) {
          candidate.unsolvedSponsoredChallenge = true;
        }
      }
    },
    addJobOfferInfo: function (candidate) {
      candidate.jobOfferInfo = [];
      for (let jobOfferId of Object.keys(candidate.jobOffers)) {
        candidate.searchString = candidate.searchString + " " + candidate.jobOffers[jobOfferId].title;
        candidate.jobOfferInfo.push({
          id: jobOfferId,
          title: candidate.jobOffers[jobOfferId].title,
          isActive: candidate.jobOffers[jobOfferId].isActive,
        });
      }
    },
    bulkArchiveCandidates: function () {
      let data = [];
      let selectedCandidates = this.$refs["candidates-table"].selectedRows;
      let onlyCompanySelected = selectedCandidates[0].onlyCompanySelected;
      for (let candidateInTable of selectedCandidates) {
        candidateInTable.isArchived = true;
        candidateInTable.seen = true;
        data.push({
          publicUserId: candidateInTable.publicUserId,
          isArchived: true,
          isSeen: true,
          isBookmarked: candidateInTable.isBookmarked,
        });
        let candidateInList = this.getCandidateInList(candidateInTable);
        if (candidateInList) {
          candidateInList.isArchived = true;
          candidateInList.seen = true;
        }
      }
      if (onlyCompanySelected) {
        services.interactsService.bulkUpdateInterestedCandidateFlags(this.companyId, data);
      } else {
        services.interactsService.bulkUpdateCandidateFlags(this.interactJobOfferId, data);
      }
      this.actionsEnabled = false;
      this.countFilters();
    },
    updateCandidateNoteInList: function (candidateInTable) {
      // The candidates in the table are deepcloned.
      // Thus, changes must be updated on the original candidates.
      let candidateInList = this.getCandidateInList(candidateInTable);
      if (!candidateInList) {
        return;
      }
      candidateInList.previewNote = truncate(candidateInTable.note.content);
    },
    updateCandidateInList: function (candidateInTable, error) {
      // The candidates in the table are deepcloned.
      // Thus, changes must be updated on the original candidates.
      let candidateInList = this.getCandidateInList(candidateInTable);
      // if an error occurs on update request the state of the cloned candidate should just be restored
      if (error) {
        candidateInTable.isBookmarked = candidateInList.isBookmarked;
        candidateInTable.isArchived = candidateInList.isArchived;
        candidateInTable.seen = candidateInList.seen;
        return;
      }

      if (candidateInList) {
        let isBookmarkedHasChanged = candidateInList.isBookmarked !== candidateInTable.isBookmarked;
        let isArchivedHasChanged = candidateInList.isArchived !== candidateInTable.isArchived;
        let isSeenHasChanged = candidateInList.seen !== candidateInTable.seen;
        candidateInList.isBookmarked = candidateInTable.isBookmarked;
        candidateInList.isArchived = candidateInTable.isArchived;
        candidateInList.seen = candidateInTable.seen;

        if (
          isSeenHasChanged &&
          (this.interactJobOfferId !== "ALL_WITHOUT_JOB_SELECTION" || this.interactJobOfferId !== "ALL_WITH_JOB_SELECTION")
        ) {
          let eventName = this.interactJobOfferId + "_seen_changed";
          eventBus.$emit(eventName);
        }

        if (isArchivedHasChanged) {
          this.countFilters();
        } else if (isBookmarkedHasChanged) {
          this.updateBookmarkCounter();
        }
      }
      if (candidateInTable.isActive) {
        this.selectActiveCandidate(candidateInTable);
      }
    },
    updateBookmarkCounter: function () {
      this.filterSettings[4].counter = this.candidatesList.filter((candidate) => candidate.isBookmarked).length;
    },
    professionSort: function (x, y, col, rowX, rowY) {
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2
      if (rowX.profession === rowY.profession) {
        // experience should be always sorted the same way desc.
        // therefore the sort function has to distinguish the current sort type for column 'itWorkExperience'.
        let sortType = this.$refs['candidates-table'].sorts.find((column) => column.field === 'itWorkExperience').type;
        if (sortType === 'asc') {
          return (x < y ? 1 : (x > y ? -1 : 0));
        }
        return (x < y ? -1 : (x > y ? 1 : 0));
      }
      return (rowX.profession < rowY.profession ? -1 : (rowX.profession > rowY.profession ? 1 : 0));
    },
    challengeSort: function (x, y, col, rowX, rowY) {
      return rowX.sponsoredChallengesSolved.length < rowY.sponsoredChallengesSolved.length
        ? -1
        : rowX.sponsoredChallengesSolved.length > rowY.sponsoredChallengesSolved.length
          ? 1
          : 0;
    },
    countFilters: function () {
      this.filterSettings[0].counter = 0;
      this.filterSettings[1].counter = 0;
      this.filterSettings[2].counter = 0;
      this.filterSettings[3].counter = 0;
      this.filterSettings[4].counter = 0;

      this.candidatesList.forEach((candidate) => {
        if (this.archivedFilterFn(candidate.isArchived, "")) {
          this.filterSettings[0].counter += 1;
          this.filterSettings[1].counter += this.filterSettings[1].filterFn(
            candidate.chatState,
            this.filterCountSubsetMode
          )
            ? 1
            : 0;
          this.filterSettings[2].counter += this.filterSettings[2].filterFn(
            candidate.chatState,
            this.filterCountSubsetMode
          )
            ? 1
            : 0;
          this.filterSettings[3].counter += this.filterSettings[3].filterFn(
            candidate.chatState,
            this.filterCountSubsetMode
          )
            ? 1
            : 0;
          this.filterSettings[4].counter += candidate.isBookmarked ? 1 : 0;
        }
      });
    },
    setActiveFilterIndex: function (filterIndex) {
      // click on active filter results in deselecting
      // then unfiltered list is set as filter (0).
      // if filter 0 is active, nothing should change.
      if (filterIndex === this.activeFilterIndex) {
        if (filterIndex === 0) {
          return;
        }

        filterIndex = 0;
      }

      this.activeFilterIndex = filterIndex;

      // activate filters again
      if (filterIndex < 4) {
        this.$set(this.columns[this.chatColumnIndex].filterOptions, "filterValue", String(filterIndex));
        this.$set(this.columns[this.chatColumnIndex].filterOptions, "enabled", true);
        this.$set(this.columns[this.bookmarkColumnIndex].filterOptions, "filterValue", "");
        this.$set(this.columns[this.bookmarkColumnIndex].filterOptions, "enabled", false);
      } else {
        this.$set(this.columns[this.bookmarkColumnIndex].filterOptions, "filterValue", String(filterIndex));
        this.$set(this.columns[this.bookmarkColumnIndex].filterOptions, "enabled", true);
        this.$set(this.columns[this.chatColumnIndex].filterOptions, "filterValue", "");
        this.$set(this.columns[this.chatColumnIndex].filterOptions, "enabled", false);
      }

      this.forceUpdateIndex = this.forceUpdateIndex + 1;
    },
    archivedFilterFn: function (isArchived, filterString) {
      // (1) if filter is enabled (E), archived candidates (A) are not shown
      // then filter returns
      // - false when candidate is archived
      // - true when candidate is not archived
      // (2) if filter is not enabled, all candidates are shown
      // then filter always returns true
      // (1) E && -A
      // (2) -E
      // (1) || (2) = E && -A || -E = -E || -A
      return !this.columns[this.archiveColumnIndex].filterOptions.enabled || !isArchived;
    },
    chatFilterFn: function (chatState, filterString) {
      if (this.activeFilterIndex > 3) {
        return true;
      }

      return this.filterSettings[this.activeFilterIndex].filterFn(chatState, this.filterCountSubsetMode);
    },
    bookmarkFilterFn: function (isBookmarked, filterString) {
      if (this.activeFilterIndex < 4) {
        return true;
      }

      return isBookmarked;
    },
    showHideArchived: function (isChecked) {
      this.columns[this.archiveColumnIndex].filterOptions.enabled = !isChecked;
      this.countFilters();
    },
    showHideRegion: function (isChecked) {
      this.columns[this.regionColumnIndex].hidden = !isChecked;
    },
    rowStyleClass: function (candidate) {
      if (this.activeCandidate && this.activeCandidate.publicUserId === candidate.publicUserId) {
        return "active-row" + (candidate.isArchived ? "-archived" : "");
      }
      return (candidate.vgtSelected ? "selected-row" : "") + (candidate.isArchived ? " archived" : "");
    },
    selectionChanged: function (params) {
      // params.selectedRows - all rows that are selected
      let selection = params.selectedRows;
      this.actionsEnabled = selection && selection.length !== 0;
    },
    editNote: function (candidate) {
      this.$refs["noteEditor"].show(candidate);
    },
    onCellClick: function (params) {
      // params.row - row object
      // params.column - column data
      // params.rowIndex - related to the currently displayed filter options
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument indicates selected or not
      // params.event - click event
      params.event.cancelBubble = true;
      eventBus.$emit("closeMoreOptionsPanel");
      eventBus.$emit("openDetailComponent", {
        channelId: params.row.channelId,
        candidate: params.row,
        interactJobOfferId: this.interactJobOfferId,
      });
    },
    selectActiveCandidate: function (candidate) {
      if (this.activeCandidate && candidate.publicUserId === this.activeCandidate.publicUserId) {
        return;
      }
      this.unselectActiveCandidate();
      if (!candidate.channelId) {
        candidate.chatState.chatMinimized = true;
        eventBus.$emit("requestCandidateChannelId", {
          candidate: candidate,
          interactJobOfferId: this.interactJobOfferId,
        });
      }
      candidate.isActive = true;
      // We want to not change the new state if candidates are selected to avoid deselecting of all selected candidates.
      // There is also the option to remove the "seen" prop in the syncronisation with the backend. (updateCandidateInList)
      // REF: PLATX-284
      if (!candidate.seen && !this.actionsEnabled) {
        candidate.seen = true;
        eventBus.$emit("candidateStateChanged", candidate);
      } else {
        this.activeCandidate = candidate;
        let candidateInList = this.getCandidateInList(this.activeCandidate);
        candidateInList.isActive = true;
        this.$set(this.activeCandidate, "isActive", true);
      }
    },
    unselectActiveCandidate: function () {
      if (this.activeCandidate) {
        let candidateInList = this.getCandidateInList(this.activeCandidate);
        candidateInList.isActive = false;
        this.activeCandidate.isActive = false;
        this.$set(this.activeCandidate, "isActive", false);
        this.activeCandidate = null;
      }
    },
    updateEmployeeIsChannelMember: function (channelId) {
      const candidate = this.getCandidateByChannel(channelId);
      if (!candidate) {
        return;
      }
      candidate.chatState.isEmployeeChannelMember = true;
      candidate.chatState.unreadMessage = false;
      const candidateInList = this.getCandidateInList(candidate);
      if (!candidateInList) {
        return;
      }
      candidateInList.chatState.isEmployeeChannelMember = true;
      candidateInList.chatState.unreadMessage = false;
      // Update list of the table
      this.$set(this.candidatesList, this.candidatesList);
      this.countFilters();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
}

.dashboard {
  padding: 0.5rem 0 3rem 1rem;
  background-color: #f9f9f9;
}

.filter-col {
  padding: 5px 5px;
}

.filter-row {
  margin-left: -5px;
}

@media (max-width: 845px) {
  .dashboard {
    padding: 0.5rem 0 3rem 0.5rem;
  }
  .filter-row {
    padding: 0 0.5rem;
  }
}

@media (max-width: 1500px) {
  .filter-wrapper .filter-display {
    height: 12rem;
  }
}

//new filter start
.filter-wrapper {
  width: 100%;
}

.filter-wrapper .filter-display,
.filter-wrapper .filter-display.active:hover {
  opacity: 1;
  width: 100%;
  background: #fff;
  box-shadow: $elevation6;
  border-radius: 5px;
  margin: 0;
  height: 6rem;
  display: flex;
  padding: 0.5rem;
  color: #565656;
  transition: all 0.25s ease-in-out;
}

.filter-wrapper .filter-display.active,
.filter-wrapper .filter-display.active:hover {
  color: #fff;
  background-image: linear-gradient(180deg, #9ed7ae, #549688);
  cursor: default;
}

.filter-wrapper .filter-display:hover {
  color: #fff;
  opacity: 0.75;
  background-image: linear-gradient(180deg, #9ed7ae, #549688);
  cursor: pointer;
  box-shadow: $elevation1-hover;
}

.filter-wrapper .filter-display .icon {
  font-size: 2rem;
  flex-direction: column;
  color: #549688;
}

.filter-wrapper .filter-display .icon .fa.fa-bookmark {
  color: #ffdd09;
}

.filter-wrapper .filter-display .icon {
  position: relative;
  align-self: center;
  text-align: right;
}

@media (max-width: 768px) {
  .filter-wrapper .filter-display .icon {
    text-align: center;
  }
  .filter-info {
    display: inline-block;
  }
  .filter-wrapper .filter-display,
  .filter-wrapper .filter-display.active:hover {
    height: 6rem;
    margin-bottom: 0;
  }
}

.filter-wrapper .filter-display .unread-bubble::after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #e74c3c;
}

.fa {
  background: linear-gradient(180deg, #9ed7ae, #549688);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.filter-wrapper .filter-display.active .icon,
.filter-wrapper .filter-display:hover .icon,
.filter-wrapper .filter-display.active:hover .icon,
.filter-wrapper .filter-display.active .name,
.filter-wrapper .filter-display.active .counter,
.filter-wrapper .filter-display:hover .name,
.filter-wrapper .filter-display:hover .counter,
.filter-wrapper .filter-display.active .icon .fa.fa-bookmark,
.filter-wrapper .filter-display:hover .icon .fa.fa-bookmark,
.filter-wrapper .filter-display:hover .fa,
.filter-wrapper .filter-display.active .fa {
  color: #fff !important;
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filter-wrapper .filter-display .filter-info {
  align-self: center;
  flex-direction: column;
  overflow: hidden;
}

.filter-wrapper .filter-display .counter {
  font-size: 2rem;
  font-weight: bolder;
  vertical-align: bottom;
}

.filter-wrapper .filter-display .name {
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  color: #dcdbdc;
  align-self: center;
  line-height: 1.1;
  hyphens: auto;
}

.filter-wrapper .filter-display .unread-bubble::before {
}

@media (max-width: 768px) {
  .filter-wrapper .filter-display .filter-info {
    display: inline;
  }
  .filter-wrapper .filter-display .counter {
    display: inline;
    font-size: 1.5rem;
  }
  .filter-wrapper .filter-display .name {
    display: inline;
    font-size: 0.7rem;
  }
  .filter-wrapper .filter-display .name-wrapper {
    line-height: 1;
  }
  .filter-wrapper .filter-display .unread-bubble::after {
    right: 0;
    top: 0;
    width: 0.6rem;
    height: 0.6rem;
  }
}

//new filter end

.search-wrapper input.search {
  width: 100%;
  height: 50px;
  padding-left: 3rem;
  box-shadow: $elevation6;
  position: relative;
}

.search-wrapper .fa {
  position: relative;
  top: 50%;
  left: 0.75rem;
}

.search-wrapper .fa-search:before {
  font-size: 1.5rem;
  color: #dcdbdc;
}

.search-icon {
  position: absolute;
  left: 0;
  z-index: 3;
  top: 0.85rem;
  padding-left: 1rem;
}

.search-wrapper {
  margin-top: 1rem;
}

.actions {
  -webkit-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transform-origin: left center;
  transform: scaleX(0);
  text-transform: uppercase;
  margin: 1rem 0.5rem;
  font-weight: bold;
  color: #606266;
}

.actions.enabled {
  transform: scaleX(1);
}

.options-row {
}

.edit-btn {
  border-radius: 50%;
  font-size: 1rem;
  background-color: #fff;
  margin: 0.5rem 1rem;
  width: 40px;
  height: 40px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;
  color: #539588;
  z-index: 3;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}

.fa.fa-pencil {
  color: #b5b5b5;
  background: none;
  -webkit-text-fill-color: #b5b5b5;
}

/* deep style for vue-ggod-table*/
::v-deep {
  .fa {
    background: linear-gradient(180deg, #9ed7ae, #549688);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fa.fa-bookmark,
  .fa.fa-bookmark-o {
    background: linear-gradient(180deg, #ffdd09, #ffcb7c);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fa.fa-ellipsis-h,
  .fa.fa-times {
    background: #b5b5b5;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .totop {
    background-color: transparent;
  }

  .vgt-responsive {
    border-radius: 10px;
  }

  .vgt-wrap {
    border-radius: 10px;
    position: relative;
    box-shadow: $elevation6;
    width: 100%;
    cursor: pointer;
  }

  .vgt-inner-wrap {
    border-radius: 10px;
  }

  table.vgt-table {
    border: none;
    z-index: 2;
  }

  .vgt-table thead tr + tr {
    display: none;
    visibility: hidden;
  }

  .vgt-table th.sortable button::before {
    right: 6px;
    top: 50%;
    margin-bottom: -10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #53958866;
  }

  .vgt-table th.sortable button::after {
    right: 6px;
    top: 50%;
    margin-top: -10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #53958866;
  }

  .vgt-table thead th.sorting-asc button::after {
    border-bottom: 8px solid #539588 !important;
  }

  .vgt-table thead th.sorting-desc button::before {
    border-top: 8px solid #539588 !important;
  }

  .vgt-table thead th {
    background: #edf4f4 !important;
    color: #539588 !important;
    font-weight: bolder;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.75rem;
  }

  .vgt-table thead th.sortable {
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  }

  .vgt-table thead th.sorting-asc,
  .vgt-table thead th.sorting-desc {
    font-style: italic;
  }

  .vgt-table tbody td {
    text-align: center;
    vertical-align: middle;
    position: relative;
  }

  .selected-row {
    background-color: #f4f3f3;
  }

  .active-row,
  .active-row-archived,
  .active-row-archived:hover,
  .active-row:hover,
  .active-row.archived .vgt-checkbox-col input[type="checkbox"],
  .active-row.archived .vgt-checkbox-col input[type="checkbox"]:checked {
    background-color: #dcdbdc !important;
  }

  tr:hover {
    background-color: #f4f3f3;
  }

  .archived,
  .archived .vgt-checkbox-col input[type="checkbox"],
  .archived .vgt-checkbox-col input[type="checkbox"]:checked {
    opacity: 0.5;
    background-color: #edf4f3 !important;
    filter: grayscale(100%);
    box-shadow: unset;
  }

  .active-row-archived .vgt-checkbox-col input[type="checkbox"],
  .active-row-archived .vgt-checkbox-col input[type="checkbox"]:checked,
  .archived .vgt-checkbox-col input[type="checkbox"],
  .archived .vgt-checkbox-col input[type="checkbox"]:checked {
    display: none;
  }

  .vgt-checkbox-col {
    background: none !important;
  }

  .vgt-checkbox-col input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0.25rem 2px 2px 2px;
    outline: none;
    border: 0;
    box-shadow: none;
  }

  .vgt-checkbox-col input[type="checkbox"]::after {
    content: "";
    width: 22px;
    height: 22px;
    border: 2px solid #539588;
    background-color: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    top: -2px;
    left: -2px;
  }

  .vgt-checkbox-col input[type="checkbox"]:checked::after {
    background-color: #539588;
    color: #fff;
    content: "\2713";
    font-size: 12px;
    font-weight: bolder;
    overflow: visible;
    text-align: center;
  }

  .vgt-table th.line-numbers,
  .vgt-table th.vgt-checkbox-col {
    border-right: none;
    border-bottom: 1px solid #dcdfe6;
    padding: 0 0.75em 0 1.25rem;
  }

  .profile-picture {
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 99px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none !important;
    background-color: transparent;
    display: block;
    margin: auto;
    position: relative;
  }

  .sponsoredChallengesSolved, .acceptedButNotSolvedChallenges {
    display: flex;
    position: absolute;
    bottom: 0;
    right: -0.5rem;
  }

  .sponsoredChallengeSolved {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    display: block;
    width: 1.5em;
    height: 1.5rem;
  }

  .acceptedButNotSolvedChallenge {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    display: block;
    width: 1.5em;
    height: 1.5rem;
    filter: grayscale(100%);
    opacity: 0.7;
  }

  .new {
    color: #e74c3c;
    font-weight: bolder;
    text-align: center;
  }

  .vgt-table .fa-archive {
    font-size: 1.25rem;
  }

  .shortcomment {
    text-align: left !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
  }

  .shortcomment.placeholder {
    color: #dcdbdc;
  }

  input {
    background-color: #fff;
    box-shadow: $elevation2;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 1rem 1rem;

    &::placeholder {
      color: #dcdbdc;
    }

    &:focus {
      outline: none;
      border-color: $eh-green;
    }
  }

  .jobOfferInfo {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .jobOfferInfo.not-active {
    font-style: italic;
    color: #888;
  }

  @media (max-width: 576px) {
    .hide-on-xs {
      display: none !important;
    }
    .vgt-table th.line-numbers,
    .vgt-table th.vgt-checkbox-col {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .hide-on-sm {
      display: none !important;
    }
  }

  @media (max-width: 992px) {
    .hide-on-md {
      display: none !important;
    }
  }

  @media (max-width: 1200px) {
    .hide-on-lg {
      display: none !important;
    }
  }

  @media (min-width: 1200px) {
    .filter-col {
      -webkit-box-flex: 0;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
</style>

<template>
  <div class="content">
    <div v-if="candidate">
      <base-error v-if="loaded && error" class="text-center w-100"
        >Es ist etwas <strong>schief</strong> gelaufen. <br />
        Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
        <a href="mailto:support@entwicklerheld.de">Support</a></base-error
      >
      <div v-if="loaded && !error" class="skills">
        <div v-if="!hasAnyWorkSamples">
          <p>Leider noch keine Arbeitsproben vorhanden.</p>
          <p
            >Fordere <strong>{{ candidate.anonymizedUserName }}</strong> gleich im Chat auf, eine Challenge mit eurem
            Unternehmen als Sponsor zu lösen und die eigenen Fähigkeiten zu zeigen!</p
          >
        </div>
        <div v-if="hasAnyWorkSamples">
          <div v-for="(workSample, i) in workSampleList" :key="workSample.acceptedChallengeUuid">
            <div class="row work-sample-background align-items-center justify-content-between">
              <div class="col-2 work-samples work-sample-logo">
                <img class="challenge-img float-left" :src="workSample.challenge.image" alt="" />
              </div>
              <div class="col-6 work-samples work-sample-info">
                <div class="row">
                  <div class="col-12">
                    <div class="work-sample-title">
                      {{ workSample.challenge.title }}
                    </div>
                  </div>
                  <div class="col-12">
                    <span
                      class="tech-pill"
                      :class="'bg-' + workSample.challenge.technology.language.toLocaleLowerCase()"
                    >
                      <i class="fa fa-code"></i>
                      <span class="tech-label">
                        {{ getLanguageName(workSample.challenge.technology.language).toUpperCase() }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-4 work-samples work-sample-button">
                <button class="btn float-right see-code-button">
                  <a @click="goToReport(workSample.acceptedChallengeUuid)">Code anschauen</a>
                </button>
                <button class="btn float-right download-button" :class="{'error': hasDownloadError(i + 1)}"
                  :disabled="hasActiveDownload()"
                  @click="downloadChallengeSolutionZip(workSample.acceptedChallengeUuid, i + 1)">
                  <i v-if="!hasActiveDownload(i + 1)" class="fa fa-download"></i>
                  <i v-if="hasActiveDownload(i + 1)" class="fa fa-spinner fa-pulse"></i>
                  Download
                </button>
                <div class="download-feedback">
                  <div v-if="hasDownloadError(i + 1)" class="download-error">
                    <i class="fa fa-exclamation-triangle" /> Fehler
                  </div>
                  <div v-if="hasDownloadSuccess(i + 1)" class="download-success">
                    <i class="fa fa-check" /> erfolgreich
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <content-loader v-if="!loaded && !error" :width="480" :height="45" style="width: 300px">
        <rect width="124" height="45" fill="#C4C4C4" />
        <rect x="145" width="194" height="45" fill="#C4C4C4" />
      </content-loader>
    </div>
  </div>
</template>

<script>
import { services } from "@/main";
import BaseError from "@/components/base/BaseError";
import { ContentLoader } from "vue-content-loader";
import { SolvedChallengesResult, DownloadChallengeSolutionZipResult } from "@/services/task-management/TaskManagementServiceResponses";
import { getLanguageName } from "@/programminglanguages";

export default {
  name: "CCandidateDetailWorkSamples",
  components: {
    BaseError,
    ContentLoader,
  },
  props: {
    candidate: {
      type: Object,
      default: () => ({}),
    },
    interactJobOfferId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      solvedChallengesRequest: new SolvedChallengesResult(),
      downloadChallengeSolutionZipRequest: new DownloadChallengeSolutionZipResult(),
      downloadErrors: [],
      downloadSuccess: [],
      solvedChallenges: [],
      workSampleList: [],
      getLanguageName: getLanguageName,
    };
  },
  computed: {
    loaded: function() {
      return this.solvedChallengesRequest.loaded;
    },
    error: function() {
      return this.solvedChallengesRequest.error;
    },
    hasAnyWorkSamples: function() {
      return this.workSampleList.length !== 0;
    },
  },
  watch: {
    "solvedChallengesRequest.loaded": function() {
      if (this.solvedChallengesRequest.hasLoaded()) {
        this.solvedChallenges = this.solvedChallengesRequest.apiData;
        this.createWorkSampleList();
      }
    },
    "downloadChallengeSolutionZipRequest.loaded": function() {
      if (this.downloadChallengeSolutionZipRequest.loaded) {
        if (!this.downloadChallengeSolutionZipRequest.error && this.downloadChallengeSolutionZipRequest.apiData['downloadLink']) {
          this.downloadErrors = this.downloadErrors.splice(0, 1, this.downloadChallengeSolutionZipRequest.i);
          this.downloadSuccess.push(this.downloadChallengeSolutionZipRequest.i);
          window.open(this.downloadChallengeSolutionZipRequest.apiData['downloadLink'], '_blank');
        }
        else {
          this.downloadErrors.push(this.downloadChallengeSolutionZipRequest.i);
          this.downloadSuccess = this.downloadSuccess.splice(0, 1, this.downloadChallengeSolutionZipRequest.i);
        }
        this.downloadChallengeSolutionZipRequest = new DownloadChallengeSolutionZipResult();
      }
    },
  },
  mounted() {
    this.requestChallenges();
  },
  methods: {
    requestChallenges: function() {
      this.solvedChallengesRequest = services.taskManagementService.getSolvedChallenge(this.candidate.publicUserId);
    },
    addChallengeToWorkSampleList: function(solvedChallenge, acceptedChallengeUuid) {
      let found = this.workSampleList.find((data) => data.acceptedChallengeUuid === acceptedChallengeUuid);
      if (!found) {
        this.workSampleList.push({
          acceptedChallengeUuid: acceptedChallengeUuid,
          challenge: solvedChallenge,
        });
      }
    },
    createWorkSampleList: function() {
      this.workSampleList = [];
      // if candidate has sponsored challenges solved, show these
      if (this.candidate.sponsoredChallengesSolved.length !== 0) {
        for (let solvedChallenge of this.candidate.sponsoredChallengesSolved) {
          solvedChallenge.image = solvedChallenge.icon;
          this.addChallengeToWorkSampleList(solvedChallenge, solvedChallenge.acceptedChallengeUuid);
        }
      }
      // if candidate has more solved challenges shared for the company, add these
      for (let candidateChallenge of this.candidate.challenges) {
        let solvedChallenge = this.solvedChallenges.find(
          (challenge) => challenge.id === candidateChallenge.challengeId
        );
        if (solvedChallenge) {
          this.addChallengeToWorkSampleList(solvedChallenge, candidateChallenge.acceptedChallengeUuid);
        }
      }
    },
    goToReport: function(acceptedChallengeUuid) {
      let routeData = this.$router.resolve({
        name: "challenge-report-by-acceptance",
        params: {
          acceptedChallengeUuid: acceptedChallengeUuid,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadChallengeSolutionZip: function(acceptedChallengeUuid, i) {
      this.downloadChallengeSolutionZipRequest = services.taskManagementService.downloadChallengeSolutionZip(acceptedChallengeUuid);
      this.downloadChallengeSolutionZipRequest.i = i;
    },
    hasDownloadError: function (i) {
      return this.downloadErrors.find(id => id === i);
    },
    hasDownloadSuccess: function (i) {
      return this.downloadSuccess.find(id => id === i);
    },
    hasActiveDownload: function (i) {
      return this.downloadChallengeSolutionZipRequest && this.downloadChallengeSolutionZipRequest.i && this.downloadChallengeSolutionZipRequest.i === i;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 1rem 1.5rem;
}
.tech-section {
  margin: 1rem 0;
  align-items: center;
}
.work-sample-background {
  background-color: #f4f8fb;
  border-radius: 1rem;
  padding: 0.75rem;
  width: 100%;
  margin: 1rem 2rem 1rem 0;
  position: relative;
}
.challenge-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  z-index: 12;
}
.work-sample-logo {
  position: relative;
}

.work-samples {
  margin: 1rem 0;
}
.work-samples button {
  background-color: #dfeaed;
  border-color: #dfeaed;
  color: #424242;
  font-size: 0.9rem;
  font-weight: 600;
}
.work-sample-title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.work-samples button:hover,
.work-samples button:active,
.work-samples button:focus {
  background-color: #c9d8dc;
  border-color: #c9d8dc;
  outline: none;
  box-shadow: none;
}
.work-sample-entry {
  margin: 0 0.5rem 0.5rem 0;
}
.work-sample-entry:hover {
  cursor: pointer;
}

.tech-header {
  position: relative;
  display: inline-block;
}

.tech-pill {
  color: white;
  overflow: hidden;
  padding: 3px 20px 5px 20px;
  font-weight: 300;
  border-radius: 100px;
  background-color: #549688;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  line-height: 1.6;
}

.tech-pill > .fa {
  position: absolute;
  bottom: -40%;
  left: 3%;
  font-size: 28px;
  color: #dfeaed1d;
}

.tech-label {
  font-size: 11px;
  font-family: "Source Code Pro", sans-serif;
}

.bg-java {
  background-color: #fa983a !important;
  color: white !important;
}
.bg-javascript {
  background-color: #f6b93b !important;
  color: white !important;
}
.bg-csharp {
  background-color: #38ada9 !important;
  color: white !important;
}
.bg-cpp {
  background-color: #e55039 !important;
  color: white !important;
}
.bg-php {
  background-color: #6a89cc !important;
  color: white !important;
}

.bg-python {
  background-color: #3c6382 !important;
  color: white !important;
}

.bg-dockerfile {
  background-color: #2980b9 !important;
  color: white !important;
}

.see-code-button{
  margin-bottom: 0.25rem;
}

.work-sample-button {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
}

.work-sample-button button{
  width: 100%;
}

.download-button.error {
  background-color: #e74c3ccc !important;
  color: #fff !important;
}

.download-feedback {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1rem;
  height: 1rem;
  text-align: center;
}

.download-error {
  text-align: center;
  font-size: 0.75rem;
  text-wrap: none;
  color: #e74c3c;
  margin-top: 0.25rem;
}

.download-success {
  text-align: center;
  font-size: 0.75rem;
  text-wrap: none;
  color: #08AB99;
  margin-top: 0.25rem;
}

@media (max-width: 400px) {
  .work-sample-button {
    padding-left: 0;
    padding-right: 0;
  }
  .work-sample-title {
    font-size: 0.8rem;
    margin-left: 0.4rem;
  }
  .tech-pill {
    margin-left: 0.4rem;
  }

  .tech-label {
    font-size: 10px;
  }
  .work-samples button {
    font-size: 0.8rem;
  }
}
</style>

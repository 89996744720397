<template>
  <div>
    <i v-if="chatState.totalMessagesSent === 0" class="fa fa-comment-o"></i>
    <i
      v-if="chatState.totalMessagesSent !== 0 && chatState.totalMessagesOfPlatformUser === 0"
      class="fa fa-comment"
    ></i>
    <i
      v-if="!chatState.unrespondedMessage && chatState.totalMessagesSent !== 0 && chatState.totalMessagesOfPlatformUser !== 0"
      class="fa fa-comments"
    ></i>
    <i v-if="chatState.unrespondedMessage && chatState.totalMessagesSent !== 0 && chatState.totalMessagesOfPlatformUser !== 0" class="fa fa-comments-o">
      <span
        v-if="
          chatState.unreadMessage && chatState.totalMessagesSent !== 0 && chatState.totalMessagesOfPlatformUser !== 0
        "
        class="unread-bubble"
      ></span>
    </i>
  </div>
</template>

<script>
export default {
  name: "PCandidateChatIcon",
  components: {},
  props: {
    chatState: {
      type: Object,
      default: () => ({
        chatMinimized: true,
        totalMessagesSent: 0,
        totalMessagesOfPlatformUser: 0,
        unreadMessage: false,
      }),
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.unread-bubble {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #e74c3c;
  border: 0;
  text-align: center;
}
</style>

<template>
  <div
    v-closable="{
      exclude: [],
      handler: 'closePanel',
    }"
    class="button-container"
  >
    <button type="button" class="btn" @click="togglePanel($event)">
      <i v-if="!hasFocus" class="fa fa-ellipsis-h"></i>
      <i v-if="hasFocus" class="fa fa-times"></i>
    </button>
    <div ref="moreOptionsPanel" :class="{ visible: hasFocus }" class="option-panel" @click="onClick($event)">
      <ul>
        <li v-for="(option, index) in optionData" :key="'option' + index">
          <input :id="'checkbox' + index" v-model="option.checked" type="checkbox" @change="valuechange(index)" />
          <label :for="'checkbox' + index">{{ option.label }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventbus";

export default {
  name: "PMoreOptionsButton",
  components: {},
  props: {
    optionData: {
      type: Array,
      default: () => [
        {
          label: "Option 1",
          checked: false,
          eventName: null,
        },
        {
          label: "Option 2",
          checked: false,
          eventName: null,
        },
      ],
    },
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  mounted() {
    eventBus.$on("closeMoreOptionsPanel", () => {
      this.closePanel();
    });
  },
  beforeDestroy() {
    eventBus.$off("closeMoreOptionsPanel");
  },
  methods: {
    togglePanel: function(event) {
      let hasFocus = !this.hasFocus;
      this.hasFocus = hasFocus;
      event.stopPropagation();
    },
    closePanel: function() {
      this.hasFocus = false;
    },
    onClick: function(event) {
      event.stopPropagation();
    },
    valuechange: function(index) {
      if (this.optionData[index].eventName) {
        eventBus.$emit(this.optionData[index].eventName, this.optionData[index].checked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  text-align: right;
  position: relative;
}

.button-container .btn {
  border-radius: 5px;
  font-size: 1.5rem;
  background-color: #fff;
  color: #777;
  margin: 0.5rem 0 0.5rem 0.5rem;
  padding: 0;
  width: 3rem;
  height: 2rem;
  line-height: 50%;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation6;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}
.button-container .option-panel {
  position: absolute;
  z-index: 1050;
  text-align: left;
  top: -95px;
  right: 0;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  margin: 0 0 0 0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  width: 20rem;
  height: auto;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation6;
  transform: scale(0);
  transform-origin: 100% 100%;
}
.button-container .option-panel.visible {
  transform: scale(1);
}
.button-container .option-panel ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.button-container .option-panel ul li {
  margin: 0.5rem 1rem;
}
.button-container .option-panel input[type="checkbox"] {
  display: none;
  visibility: hidden;
}
.button-container .option-panel label {
  color: #606060;
  cursor: pointer;
}
.button-container .option-panel input[type="checkbox"] + label::before {
  content: "";
  margin-right: 1rem;
  width: 22px;
  height: 22px;
  border: 2px solid #539588;
  background-color: #fff;
  border-radius: 7px;
  display: block;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  float: left;
}
.button-container .option-panel input[type="checkbox"]:checked + label::before {
  background-color: #539588;
  color: #fff;
  content: "\2713";
  font-size: 12px;
  font-weight: bolder;
  overflow: visible;
  text-align: center;
}

.fa.fa-ellipsis-h {
  padding-top: 4px;
}
.fa.fa-times {
  font-weight: 100;
}
</style>

<template>
  <div
    ref="detail"
    v-closable="{
      exclude: [],
      handler: 'closeDetail',
    }"
    :class="{ 'close-detail': closed }"
    class="detail-component"
  >
    <div v-if="candidate" class="detail-content">
      <div class="detail-header">
        <div
          v-for="(tab, index) in tabData"
          :key="'tab' + index"
          :class="{ active: activeTabIndex === index }"
          class="header-tab"
          @click="switchTab(index)"
        >
          <i class="tab-icon" :class="tab.iconClass"></i>
          <span class="tab-label">{{ tab.name }}</span>
        </div>
        <div class="detail-close" @click="closeDetailSidebar()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="detail-candidate row">
        <div class="col-4 col-md-3 my-auto">
          <div class="profile-image" :style="'background-image: url(\'' + candidate.anonymizedPicture + '\')'">
            <div v-if="hasSponsoredChallengesSolved" class="sponsoredChallengesSolved">
              <span
                v-b-tooltip.hover.top.noninteractive.d500="'Gesponserte Challenge gelöst'"
                class="sponsoredChallengeSolved"
                :style="
                  'background-image: url(https://res.cloudinary.com/dspi5zyaa/image/upload/v1619766878/eh-platform/icons/smashicons_Development_laptop-coding-icon-4-yellow_256_1.png)'
                "
              ></span>
            </div>
          </div>
        </div>
        <div class="info col-4 col-md-6 my-auto">
          <div class="name">{{ candidate.anonymizedUserName }}</div>
          <div class="profession">{{ candidate.profession }}</div>
        </div>
        <div class="actions col-4 col-md-3 my-auto text-right">
          <PArchiveButton class="action-button" :candidate="candidate" />
          <PBookmarkButton class="action-button" :candidate="candidate" />
        </div>
      </div>
      <div :class="{ 'chat-open': !candidateChatClosed }" class="tab-view">
        <div class="tab-wrapper">
          <div v-if="activeTab" class="tab-content">
            <component :is="activeTab.componentName" :candidate="candidate" :interactJobOfferId="interactJobOfferId" />
          </div>
          <div
            v-show="!candidateChatClosed"
            class="chat-wrapper"
            :class="[{ 'chat-open': !candidateChatClosed }, { minimized: candidateChatMinimized }]"
          >
            <CCandidateDetailChat
              ref="candidateChat"
              :minimized="candidateChatMinimized"
              :closed="candidateChatClosed"
            />
          </div>
        </div>
        <div v-if="candidateChatClosed" class="row">
          <div class="col-12 text-center buttons">
            <base-error v-if="channelIdRequestError" class="text-center w-100 channel-id-request-error"
              >Es ist etwas <strong>schief</strong> gelaufen. <br />
              Bitte probieren Sie es gleich nochmal oder wenden Sie sich an unseren
              <a href="mailto:support@entwicklerheld.de">Support</a></base-error
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseError from "@/components/base/BaseError";
import { eventBus } from "@/eventbus";
import PBookmarkButton from "@/components/candidates/PBookmarkButton";
import PArchiveButton from "@/components/candidates/PArchiveButton";
import PCandidateDetailGeneral from "@/components/candidates/PCandidateDetailGeneral";
import PCandidateDetailCV from "@/components/candidates/PCandidateDetailCV";
import CCandidateDetailWorkSamples from "@/components/candidates/CCandidateDetailWorkSamples";
import CCandidateDetailNote from "@/components/candidates/CCandidateDetailNote";
import CCandidateDetailChat from "@/components/candidates/CCandidateDetailChat";
import {services} from "@/main";

export default {
  name: "CCandidateDetailsSidebar",
  components: {
    BaseError,
    PBookmarkButton,
    PArchiveButton,
    PCandidateDetailGeneral,
    PCandidateDetailCV,
    CCandidateDetailWorkSamples,
    CCandidateDetailNote,
    CCandidateDetailChat,
  },
  data() {
    return {
      tabData: [
        {
          name: "Allgemein",
          componentName: "PCandidateDetailGeneral",
          iconClass: "fa fa-user-o",
        },
        {
          name: "Lebenslauf",
          componentName: "PCandidateDetailCV",
          iconClass: "fa fa-id-card-o",
        },
        {
          name: "Arbeitsproben",
          componentName: "CCandidateDetailWorkSamples",
          iconClass: "fa fa-file-code-o",
        },
        {
          name: "Notizen",
          componentName: "CCandidateDetailNote",
          iconClass: "fa fa-sticky-note-o",
        },
      ],
      channelRequesting: false,
      channelIdRequestError: null,
      closed: true,
      candidateChatClosed: true,
      candidateChatMinimized: false,
      candidate: {
        publicUserId: "",
        sponsoredChallengesSolved: [],
        chatState: {
          chatMinimized: true,
          totalMessagesSent: 0,
          totalMessagesOfPlatformUser: 0,
          unreadMessage: false,
        },
      },
      interactJobOfferId: "",
      activeTabIndex: 0,
      activeTab: null,
      joinChannelForCompanyRequest: null,
    };
  },
  computed: {
    hasSponsoredChallengesSolved: function() {
      return this.candidate.sponsoredChallengesSolved && this.candidate.sponsoredChallengesSolved.length !== 0;
    },
  },
  watch: {
    "joinChannelForCompanyRequest.loaded": function() {
      if (this.joinChannelForCompanyRequest.loaded) {
        let channelId = this.joinChannelForCompanyRequest.apiData.channelId;
        // emit event to canidates table in order to update the candidate's data
        if (channelId && !this.joinChannelForCompanyRequest.error) {
          eventBus.$emit("updateEmployeeIsChannelMember", {
            channelId: channelId,
          });
        }
        // check if current candidate has been switched while request has been loading
        if (!this.candidate || !channelId || this.candidate.channelId !== channelId) {
          return;
        }
        eventBus.$emit("joinedCurrentChannelEvent", {
          memberId: this.joinChannelForCompanyRequest.apiData.memberId,
          channelMemberId: this.joinChannelForCompanyRequest.apiData.channelMemberId,
          channelId: channelId,
          error: this.joinChannelForCompanyRequest.error,
        });
      }
    },
  },
  created: function() {
    eventBus.$on("joinCurrentChannelEvent", () => {
      if (this.candidate && this.candidate.channelId) {
        this.joinChannelForCompanyRequest = services.messageService.joinChannelForCompany(this.candidate.channelId);
        return;
      }
      eventBus.$emit("joinedCurrentChannelEvent", {
        error: 'no candidate or channel id',
      });
    });
    eventBus.$on("openDetailComponent", (data) => {
      this.resetChannelIdRequestError();
      if (this.candidate.publicUserId !== data.candidate.publicUserId) {
        if (data.candidate.channelId) {
          this.candidateChatClosed = false;
          this.candidateChatMinimized = data.candidate.chatState.chatMinimized;
        } else {
          this.candidateChatMinimized = true;
          data.candidate.chatState.chatMinimized = true;
          this.candidateChatClosed = true;
        }
      } else {
        this.candidateChatMinimized = data.candidate.chatState.chatMinimized;
      }

      this.candidate = data.candidate;
      this.$refs.candidateChat.userIsChatMember = this.candidate.chatState.isEmployeeChannelMember;
      this.interactJobOfferId = data.interactJobOfferId;
      this.switchTab(0);
      this.$nextTick(() => {
        this.selectCandidateChannel();
      });

      if (this.closed) {
        this.openDetail();
      }
    });
    eventBus.$on("closeDetailComponent", () => {
      if (!this.closed) {
        this.closeDetail();
      }
    });
    eventBus.$on("showChannelIdRequestError", (error) => {
      this.resetChannelIdRequestError(error);
    });
    eventBus.$on("openCandidateChatComponent", (data) => {
      this.resetChannelIdRequestError(null);
      if (!data.candidate.channelId) {
        this.channelRequesting = true;
        eventBus.$emit("requestCandidateChannelId", {
          candidate: data.candidate,
          interactJobOfferId: data.interactJobOfferId,
        });
      } else {
        if (this.closed) {
          eventBus.$emit("openDetailComponent", {
            candidate: data.candidate,
            interactJobOfferId: data.interactJobOfferId,
          });
        } else {
          this.candidate = data.candidate;
          this.candidateChatClosed = false;
          this.candidateChatMinimized = this.candidate.chatState.chatMinimized;
          this.$nextTick(() => {
            this.selectCandidateChannel();
          });
        }
      }
    });
    eventBus.$on("minimizeCandidateChatComponent", () => {
      this.candidateChatMinimized = true;
      this.candidate.chatState.chatMinimized = true;
    });
    eventBus.$on("maximizeCandidateChatComponent", () => {
      this.candidateChatMinimized = false;
      this.candidate.chatState.chatMinimized = false;
      this.$nextTick(() => {
        this.selectCandidateChannel();
      });
    });
  },
  beforeDestroy() {
    eventBus.$off("joinCurrentChannelEvent");
    eventBus.$off("openDetailComponent");
    eventBus.$off("closeDetailComponent");
    eventBus.$off("showChannelIdRequestError");
    eventBus.$off("openCandidateChatComponent");
    eventBus.$off("minimizeCandidateChatComponent");
    eventBus.$off("maximizeCandidateChatComponent");
  },
  methods: {
    resetChannelIdRequestError: function(error) {
      this.channelRequesting = false;
      this.channelIdRequestError = error;
    },
    closeDetailSidebar: function() {
      eventBus.$emit("closeDetailComponent");
    },
    closeDetail: function() {
      if (!this.closed) {
        this.closed = true;
      }
    },
    openDetail: function() {
      if (this.closed) {
        this.closed = false;
      }
    },
    switchTab: function(index) {
      this.activeTabIndex = index;
      this.activeTab = this.tabData[index];
    },
    openChat: function() {
      this.candidate.chatState.chatMinimized = false;
      eventBus.$emit("openCandidateChatComponent", {
        candidate: this.candidate,
        interactJobOfferId: this.interactJobOfferId,
      });
    },
    selectCandidateChannel: function() {
      if (this.candidate.channelId) {
        eventBus.$emit("candidateChatSelectChannel", this.candidate);
      }
    },
    preventBubbling(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-component {
  transform: translate(0, 0);
  transition: transform 0.35s ease-out, opacity 0.25s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  border-width: 0;
  pointer-events: none;
  z-index: 1001;
  font-size: 13px;
  outline: none;
}
@media (max-width: 600px) {
  .detail-component {
    max-width: 100%;
  }
}
@media (min-width: 601px) {
  .detail-component {
    max-width: 600px;
  }
}
.tab-icon {
  color: #606266;
  height: 20px;
  font-size: 1rem;
}

.close-detail {
  transform: translateX(100%) !important;
  opacity: 0;
}
.detail-close {
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  background-color: white;
}

.fa-times-circle-o,
.fa-times-circle,
.fa-times {
  font-size: 1rem;
}
.detail-close:hover {
  opacity: 1;
}
.detail-content {
  transition: all 0.25s ease-in-out;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  color: #606266;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid transparent;
  outline: 0;
  border-radius: 0;
}
.detail-header {
  padding: 0;
  width: 100%;
  min-height: 45px;
  margin: 0;
  text-transform: uppercase;
  font-size: 0.9rem;
  white-space: nowrap;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.detail-header div {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #f4f3f3;
  padding: 1rem 0.25rem 0.5rem 0.25rem;
  cursor: pointer;
}
.detail-close {
  flex-shrink: 2;
  padding: 1rem 1.25rem 0.5rem 1.25rem !important;
}

.detail-header div:hover {
  border-bottom-color: #a9cac4;
}
.detail-header div.active {
  border-bottom-color: #538588;
  cursor: default;
}

.tab-icon {
  display: none;
}
@media (max-width: 500px) {
  .tab-label {
    display: none !important;
  }
  .tab-icon {
    display: inline-block;
  }
  .detail-close {
    flex-shrink: 1;
    padding: 1rem 0.25rem 0.5rem 0.25rem !important;
  }
}
.detail-candidate {
  min-height: 150px;
  margin: 0.5rem 0;
  -webkit-box-align: center !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}
.detail-candidate .info {
  vertical-align: middle;
  margin: 0rem;
}
.detail-candidate .info .name {
  font-weight: bolder;
  font-size: 1.5rem;
}
.detail-candidate .info .profession {
  font-size: 1rem;
}
.profile-image {
  background-size: cover;
  width: 7rem;
  height: 7rem;
  //margin: 1rem 1.5rem;
  margin: 0;
  display: block;
  border-radius: 50%;
  object-fit: contain;
  background-color: white;
}

.sponsoredChallengesSolved {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 1rem;
  cursor: pointer;
}

.sponsoredChallengeSolved {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  display: block;
  width: 3em;
  height: 3rem;
}

.tab-view {
  height: 100%;
  display: grid;
  grid-template-rows: auto 5rem;
}
.tab-wrapper {
  overflow-y: auto;
  height: calc(100vh - 265px);
}

.tab-wrapper .tab-content {
  width: 100%;
  display: block;
}
.chat-wrapper {
  position: absolute;
  display: block;
  width: 100%;
  z-index: -1;
  transition: all 0.25s ease-in-out;
  bottom: 0;
}
.chat-wrapper.chat-open {
  z-index: 100;
}
.chat-wrapper.chat-open.minimized {
  transform: translateY(100%) translateY(-50px);
}
.buttons {
  width: 100%;
  align-items: center;
  display: block;
  padding: 0 1.5rem 1rem 1.5rem;
}
.buttons .channel-id-request-error {
  position: absolute;
  bottom: 5rem;
  z-index: 1000;
}
.buttons .chat-button-start {
  outline: none;
  border-radius: 10px;
  padding: .65rem 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  background-image: linear-gradient(180deg, #9ed7ae, #549688);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}
.buttons .chat-button-start i {
  padding: 0 0.5rem;
}
.detail-close i {
  font-size: 1.3rem;
  -webkit-text-stroke: 3px white;
}
</style>
